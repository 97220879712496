import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { useModals } from './ModalStack';

const ConfirmModal = ({ show, handleProceed, title, message }) => {
  const { closeModal } = useModals();
  return (
    <Modal
      show={show}
      onHide={closeModal}
      dialogClassName="max-w650 custom-modal"
      centered
    >
      <Modal.Header closeButton className="border-0 px-0 py-0">
        <Modal.Title className="w100">
          <h3 className="text-center subtitle custom-modal-title">{title}</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-6 px-0 mx-30 text-center">
        <div>
          <p>{message}</p>
        </div>
      </Modal.Body>

      <Modal.Footer className="text-center d-block border-0 module-py-15 mx-30">
        <Button
          id="close-void"
          bsPrefix="btn_outline_md my-0"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          id="save-void"
          bsPrefix="btn_default_md ms-7 my-0"
          onClick={handleProceed}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleProceed: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default ConfirmModal;
