import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import clsx from 'clsx';

import commonStyles from '../styles.module.css';

const StatusOption = ({
  options,
  showStatusFilter,
  setShowStatusFilter,
  filterStatus,
  handleFilterStatusChange,
  isLoading,
  handleShowOverlay,
  width
}) => {
  const statusFilterOverlay = () => {
    return (
      <Popover className={commonStyles.overlay}>
        <Popover.Body>
          <div className={commonStyles.overlayContent}>
            {options.map((item, index) => {
              return (
                <div
                  key={index}
                  className={clsx('clickable', commonStyles.optionItem, {
                    [commonStyles.selectedItem]:
                      item?.value === filterStatus?.value
                  })}
                  onClick={() => {
                    setShowStatusFilter(false);
                    handleFilterStatusChange(item);
                  }}
                  aria-hidden
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        !event.target.closest(`.${commonStyles.overlay}`) &&
        !event.target.closest(`.${commonStyles.filterDropDown}`)
      ) {
        setShowStatusFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      show={showStatusFilter && !isLoading}
      placement="bottom-end"
      overlay={statusFilterOverlay()}
    >
      <div
        className={`bodybold ${commonStyles.filterDropDown}`}
        style={{ width }}
        onClick={handleShowOverlay}
        aria-hidden
      >
        {filterStatus?.label}
        <i className="icon_arrowDown_solid ms-2" />
      </div>
    </OverlayTrigger>
  );
};

StatusOption.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  showStatusFilter: PropTypes.bool.isRequired,
  setShowStatusFilter: PropTypes.func.isRequired,
  filterStatus: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  handleFilterStatusChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleShowOverlay: PropTypes.func.isRequired,
  width: PropTypes.string
};

StatusOption.defaultProps = {
  width: '130px'
};

export default StatusOption;
