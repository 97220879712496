import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';
import { ReactComponent as MagnifyingIcon } from '../../assets/img/icons/magnifying-icon.svg';

const Search = ({
  handleClickSearch,
  className,
  savedQuery,
  setSavedQuery,
  placeholder
}) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  return (
    <div className={clsx(styles.search, className)}>
      <input
        className="input_area w_100"
        placeholder={placeholder}
        onKeyUp={e => {
          if (e.target.value.trim() === '' && searchKeyword.length > 0) {
            handleClickSearch(e.target.value.trim());
          }
          if (setSavedQuery) setSavedQuery(e.target.value.trim());
          else setSearchKeyword(e.target.value.trim());
        }}
      />
      <MagnifyingIcon
        className={clsx(styles.icon, { clickable: handleClickSearch })}
        onClick={() => {
          if (handleClickSearch) handleClickSearch(savedQuery ?? searchKeyword);
        }}
      />
    </div>
  );
};
Search.propTypes = {
  handleClickSearch: PropTypes.func,
  className: PropTypes.string,
  savedQuery: PropTypes.string,
  setSavedQuery: PropTypes.func,
  placeholder: PropTypes.string
};

Search.defaultProps = {
  handleClickSearch: null,
  className: '',
  savedQuery: null,
  setSavedQuery: null,
  placeholder: 'Search'
};

export default Search;
