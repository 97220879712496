import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import clsx from 'clsx';

import styles from './styles.module.css';
import NewLoader from '../NewLoader';

const DataTable = ({
  rowSelection,
  data,
  columns,
  pageNo,
  pageSize,
  total,
  handleTableChange,
  isLoading,
  scroll
}) => {
  const getSortIcon = useCallback(({ sortOrder }) => {
    return (
      <i
        className={clsx({
          [styles.icon_sorter_down]: sortOrder === 'descend',
          [styles.icon_sorter_up]: sortOrder !== 'descend',
          [styles.active]: sortOrder
        })}
      />
    );
  }, []);
  return (
    <Table
      rowKey={record => record.id ?? record.index}
      className="custom-table"
      rowSelection={rowSelection}
      dataSource={data.map((item, index) => ({ ...item, index: index + 1 }))}
      columns={columns.map(col => ({
        ...col,
        sortIcon: getSortIcon,
        showSorterTooltip: false
      }))}
      pagination={{
        current: pageNo,
        pageSize,
        total,
        showTotal: totalCount => `Total ${totalCount} items`,
        className: 'body',
        position: ['bottomLeft'],
        // pageSizeOptions: ['5', '10', '25', '50']
        showSizeChanger: false
      }}
      onChange={handleTableChange}
      scroll={isLoading ? {} : scroll}
      locale={{
        emptyText: (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <p>{isLoading ? '' : 'No records found.'}</p>
          </div>
        )
      }}
      loading={{
        spinning: isLoading,
        indicator: (
          <div
            style={{
              width: '80px',
              height: '100px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <NewLoader />
          </div>
        )
      }}
    />
  );
};

DataTable.propTypes = {
  rowSelection: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageNo: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleTableChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scroll: PropTypes.shape({})
};

DataTable.defaultProps = {
  rowSelection: null,
  scroll: { x: 'max-content' }
};

export default DataTable;
