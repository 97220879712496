import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  getCountryCallingCode
} from 'react-phone-number-input';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { Divider, Input, Select, Space } from 'antd';
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';

import styles from './style.module.css';
import ImportDropZone from './ImportDropZone';
import { CSVToJSON } from '../../../../../helpers/fileUploadUtil';
import {
  createTag,
  editAdSubscriber,
  getAllTags,
  getSubscriberById,
  importAdSubscribers
} from '../../../../../services/userService';
import templateFile from '../../../../../assets/Tezsign_Contacts_Sample.csv';
import { useCustomToast } from '../../../../../context/ToastContext';
import { useLoader } from '../../../../../context/LoaderContext';
import { useModals } from '../../../../common/ModalStack';
import { getCountryOptionList } from '../../../../../helpers/utils';
import { SubscriberStatusOptions } from '../../../../../helpers/maps';
import CustomSelectWithAdd from './CustomSelectWithAdd';

const ACCEPTED_FILE_TYPES = ['text/csv'];
let keyDownValue = null;

const AddSubscriberModal = ({ show, id, getData }) => {
  const { setShowOverlayLoader } = useLoader();
  const { showToast } = useCustomToast();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModals();
  const [tab, setTab] = useState(id ? 'manually' : 'upload');
  const [dataToImport, setDataToImport] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const previousTags = useRef(tagList);
  const [importResult, setImportResult] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      country: 'SG',
      countryMobileCode: '65',
      status: 'Subscriber'
    }
  });

  const getTagList = async () => {
    const res = await getAllTags({ pageSize: 10000 });
    if (res.error) {
      showToast('error', 'Error retrieving tags');
      // return;
    }
    setTagList(res.itemList);
  };

  const handleCreateTag = async tagName => {
    setIsCreatingTag(true);
    const res = await createTag({
      name: tagName,
      type: 'AD_SUBSCRIPTION_TAG'
    });
    if (res.error) {
      // showToast('error', 'Error adding tag');
      setIsCreatingTag(false);
      return;
    }
    showToast('success', 'Tag created successfully');
    await getTagList();
    setIsCreatingTag(false);
  };

  const onDrop = useCallback(file => {
    const reader = new FileReader();
    reader.readAsText(file[0]);
    reader.onload = () => {
      setShowOverlayLoader(true);
      const jsonResult = CSVToJSON(reader.result);
      const updatedData = [];
      jsonResult.map(item => {
        // invalid email
        if (
          !/^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            item['Email*']
          )
        ) {
          return null;
        }
        // invalid phone
        let countryMobileCode = '';
        let mobileNumber;
        if (item.Phone) {
          const phoneNumberParse = parsePhoneNumber(
            item.Phone?.toString().startsWith('+')
              ? `${item.Phone}`
              : `+${item.Phone}`
          );

          countryMobileCode =
            phoneNumberParse?.countryCallingCode ||
            item.countryMobileCode ||
            '65';
          mobileNumber = phoneNumberParse?.nationalNumber || item.Phone;
          if (!isPossiblePhoneNumber(`+${countryMobileCode}${mobileNumber}`)) {
            return null;
          }
        }
        updatedData.push({
          firstName: item['First Name*'],
          lastName: item['Last Name'],
          email: item['Email*'],
          countryMobileCode,
          mobileNumber,
          organization: item.Organization,
          designation: item.Designation
        });
        return null;
      });
      setDataToImport(updatedData);
      setShowOverlayLoader(false);
    };
  }, []);

  const onSubmit = async data => {
    if (tab === 'manually') {
      const updatedData = {
        id,
        ...data,
        tags: data.tags?.map(tag => ({ tagId: tag })) ?? []
      };
      let res = null;
      if (id) {
        res = await editAdSubscriber(updatedData);
      } else {
        res = await importAdSubscribers([updatedData]);
      }
      if (res.error) {
        showToast('error', res.error);
        return;
      }
      closeModal();
      getData();
    } else {
      const res = await importAdSubscribers(
        dataToImport.map(item => ({
          ...item,
          tags: data.tags?.map(tag => ({ tagId: tag })) ?? [],
          status: data.status,
          overwrite: data.updateExisting
        }))
      );
      setImportResult(res);
    }
  };

  const getSubscriber = async () => {
    const res = await getSubscriberById(id);
    if (res.error) {
      showToast('error', res.error);
      return;
    }
    setValue('firstName', res.firstName);
    setValue('lastName', res.lastName);
    setValue('email', res.email);

    setValue('countryMobileCode', res.countryMobileCode ?? '65');
    const parsedNo = parsePhoneNumber(
      `+${res.countryMobileCode}${res.mobileNumber}`
    );
    setValue('country', parsedNo?.country ?? 'SG');
    setValue('mobileNumber', res.mobileNumber);
    setValue('organization', res.organization);
    setValue('designation', res.designation);
    setValue('status', res.status);
    setValue('tags', res.tags.map(tag => tag.tagId) ?? []);
  };

  useEffect(() => {
    getTagList();
    if (id) {
      getSubscriber();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        closeModal();
        getData();
      }}
      dialogClassName="max-w1000 custom-modal"
      centered
      enforceFocus={false}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0 px-0 py-0">
          <Modal.Title className="w100">
            {!id && (
              <Tabs
                id="controlled-tab-example"
                activeKey={tab}
                onSelect={item => {
                  setTab(item);
                  setDataToImport(null);
                  setImportResult(null);
                }}
                className="tab_list bodybold"
              >
                <Tab eventKey="manually" title="Manually" />
                <Tab eventKey="upload" title="Upload File" />
              </Tabs>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-6 px-0 mx-30">
          {tab === 'manually' && (
            <>
              <div className="subtitle mb-4 text-center">
                {id ? 'Edit Subscriber' : 'Add Subscriber Manually'}
              </div>
              <p className="text-center">Enter subscriber information below.</p>
              <div className={styles.recipientContainer}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FloatingLabel controlId="firstName" label="First Name*">
                      <Form.Control
                        size="sm"
                        placeholder="First Name*"
                        name="firstName"
                        type="text"
                        autoComplete="off"
                        className={clsx('input_area', 'body', {
                          'is-invalid': errors.firstName
                        })}
                        {...register('firstName', {
                          required: 'Please enter first name',
                          maxLength: {
                            value: 255,
                            message:
                              'First Name should not exceed 255 characters'
                          }
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        as={
                          <span className="text-danger d-inline-block fs-8 ms-2" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} lg={6} className="mt-5 mt-lg-0">
                    <FloatingLabel controlId="firstName" label="Last Name">
                      <Form.Control
                        size="sm"
                        placeholder="Last Name"
                        name="lastName"
                        type="text"
                        autoComplete="off"
                        className={clsx('input_area', 'body', {
                          'is-invalid': errors.lastName
                        })}
                        {...register('lastName', {
                          maxLength: {
                            value: 255,
                            message:
                              'Last Name should not exceed 255 characters'
                          }
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        as={
                          <span className="text-danger d-inline-block fs-8 ms-2" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} lg={6} className="mt-5">
                    <FloatingLabel controlId="email" label="Email*">
                      <Form.Control
                        size="sm"
                        placeholder="Email*"
                        name="email"
                        type="text"
                        autoComplete="off"
                        className={clsx('input_area', 'body', {
                          'is-invalid': errors.email
                        })}
                        {...register('email', {
                          required: 'Please enter email',
                          maxLength: {
                            value: 255,
                            message: 'Email should not exceed 255 characters'
                          },
                          pattern: {
                            value:
                              /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                            message: 'Please enter valid email'
                          }
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        as={
                          <span className="text-danger d-inline-block fs-8 ms-2" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Row className="m-0 d-flex w-100">
                      <Col xs={12} lg={5} className="p-0 mt-5">
                        <Controller
                          name="country"
                          control={control}
                          render={({ field }) => (
                            <Select
                              style={{
                                width: '100%'
                              }}
                              className="select-with-search"
                              dropdownStyle={{ zIndex: 1100 }}
                              placeholder="Select a country"
                              showSearch
                              options={getCountryOptionList()}
                              filterOption={(input, option) => {
                                return (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                              {...field}
                              onChange={value => {
                                setValue(
                                  'countryMobileCode',
                                  getCountryCallingCode(value)
                                );
                                field.onChange(value);
                              }}
                            />
                          )}
                          rules={{
                            required: 'Please select one'
                          }}
                        />
                      </Col>
                      <Col xs={12} lg={7} className="ps-lg-2 p-0 mt-5">
                        <FloatingLabel controlId="mobileNumber">
                          <InputGroup>
                            <InputGroup.Text
                              className={clsx(styles.countryCode, 'body')}
                            >
                              +{getCountryCallingCode(watch('country') || 'SG')}
                            </InputGroup.Text>
                            <Form.Control
                              size="sm"
                              placeholder="Phone"
                              name="mobileNumber"
                              type="tel"
                              autoComplete="tel"
                              className={clsx('input_area', 'body', {
                                'is-invalid': errors.mobileNumber
                              })}
                              {...register('mobileNumber', {
                                maxLength: {
                                  value: 15,
                                  message:
                                    'Phone number should not exceed 15 digits'
                                },
                                validate: value => {
                                  if (value) {
                                    const realValue = `+${getCountryCallingCode(
                                      watch('country')
                                    )}${value}`;
                                    const isPossible =
                                      isPossiblePhoneNumber(realValue);
                                    if (!isPossible) {
                                      return 'Please enter valid phone';
                                    }
                                  }
                                  return true;
                                }
                              })}
                              onKeyDown={e => {
                                const { value } = e.target;
                                keyDownValue = value;
                              }}
                              onChange={e => {
                                const { value } = e.target;
                                if (keyDownValue === '') {
                                  const isPossible =
                                    isPossiblePhoneNumber(value);
                                  if (isPossible) {
                                    const phoneNumber = parsePhoneNumber(value);
                                    setValue(
                                      'mobileNumber',
                                      phoneNumber.nationalNumber
                                    );
                                    setValue('country', phoneNumber.country);
                                  }
                                }
                              }}
                            />
                          </InputGroup>
                          <ErrorMessage
                            errors={errors}
                            name="mobileNumber"
                            as={
                              <span className="text-danger d-inline-block fs-8 ms-2" />
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={6} className="mt-5">
                    <FloatingLabel
                      controlId="organization"
                      label="Organization"
                    >
                      <Form.Control
                        size="sm"
                        placeholder="Organization"
                        name="organization"
                        type="text"
                        autoComplete="off"
                        className={clsx('input_area', 'body', {
                          'is-invalid': errors.organization
                        })}
                        {...register('organization', {
                          maxLength: {
                            value: 255,
                            message:
                              'Organization should not exceed 255 characters'
                          }
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="organization"
                        as={
                          <span className="text-danger d-inline-block fs-8 ms-2" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} lg={6} className="mt-5">
                    <FloatingLabel controlId="designation" label="Designation">
                      <Form.Control
                        size="sm"
                        placeholder="Designation"
                        name="designation"
                        type="text"
                        autoComplete="off"
                        className={clsx('input_area', 'body', {
                          'is-invalid': errors.designation
                        })}
                        {...register('designation', {
                          maxLength: {
                            value: 255,
                            message:
                              'designation should not exceed 255 characters'
                          }
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="designation"
                        as={
                          <span className="text-danger d-inline-block fs-8 ms-2" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} className="mt-5">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          style={{
                            width: '100%'
                          }}
                          className="select-with-search"
                          dropdownStyle={{ zIndex: 1100 }}
                          options={SubscriberStatusOptions}
                          filterOption={(input, option) => {
                            return (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          {...field}
                        />
                      )}
                      rules={{
                        required: 'Please select one'
                      }}
                    />
                  </Col>
                  <Col xs={12} className="mt-5">
                    <span className="bodybold">Tag Your Subscriber</span>
                    <p className="mt-3">
                      Tags are labels you create to organize subscribers. Use
                      them for personalized marketing, segmentation, or
                      automation. Tags will appear in your subscribers table
                      after import.
                    </p>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Controller
                      name="tags"
                      control={control}
                      render={({ field }) => (
                        <CustomSelectWithAdd
                          style={{
                            width: '100%'
                          }}
                          placeholder="Search or create tags"
                          inputPlaceholder="Please enter tag name"
                          options={tagList.map(item => ({
                            label: item.name,
                            value: item.id
                          }))}
                          field={field}
                          handleAdd={handleCreateTag}
                          loading={isCreatingTag}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
          {tab === 'upload' && (
            <>
              <div className="subtitle mb-4 text-center">Import File</div>
              <p className="text-center">
                Add multiple subscribers at once by utilizing our custom CSV
                template. Download the template, populate it, <br />
                and upload when you’re ready.
              </p>
              {!dataToImport ? (
                <div className={styles.recipientContainer}>
                  <div className="d-flex justify-content-end mb-6">
                    <a
                      href={templateFile}
                      download="Tezsign_Contacts_Sample.csv"
                    >
                      <Button
                        id="download-csv"
                        variant="outline"
                        bsPrefix="btn_square_sm"
                      >
                        Download CSV
                      </Button>
                    </a>
                  </div>
                  <Dropzone
                    accept={ACCEPTED_FILE_TYPES}
                    disablePreview={false}
                    multiple={false}
                    noClick={false}
                    onDropAccepted={onDrop}
                    onDropRejected={() => {
                      showToast('error', 'Please upload only CSV file');
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className={styles.uploadArea}>
                        <input {...getInputProps()} />
                        <ImportDropZone fileTips="*Only CSV file" />
                      </div>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <>
                  <div className={styles.importedCountContainer}>
                    <div className={styles.innerImportedCountContainer}>
                      <span className="bodylarge">
                        {importResult ? (
                          <>
                            <b>{importResult.successCount}</b> contacts updated
                            or added
                          </>
                        ) : (
                          <>
                            <b>{dataToImport?.length}</b> contacts recognized in
                            this file
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  {!importResult && (
                    <Row>
                      <Col xs={12} className="mt-5">
                        <span className="bodybold">Status</span>
                        <p className="mt-3">
                          Selecting the 'Subscribed' status for your subscribers
                          indicates that you have obtained their consent to
                          receive marketing communications.
                        </p>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <Controller
                          name="status"
                          control={control}
                          render={({ field }) => (
                            <Select
                              style={{
                                width: '100%'
                              }}
                              className="select-with-search"
                              dropdownStyle={{ zIndex: 1100 }}
                              options={SubscriberStatusOptions}
                              filterOption={(input, option) => {
                                return (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                              {...field}
                            />
                          )}
                          rules={{
                            required: 'Please select one'
                          }}
                        />
                      </Col>
                      <Col xs={12} className="mt-5">
                        <span className="bodybold">Tag Your Subscribers</span>
                        <p className="mt-3">
                          Tags are labels you create to organize subscribers.
                          Use them for personalized marketing, segmentation, or
                          automation. Tags will appear in your subscriber table
                          after import.
                        </p>
                      </Col>
                      {/* <Col xs={12} className="mt-3">
                        <Controller
                          name="tags"
                          control={control}
                          render={({ field }) => (
                            <Select
                              style={{
                                width: '100%'
                              }}
                              // showSearch
                              mode="multiple"
                              placeholder="Search or create tags"
                              className="select-with-search"
                              dropdownStyle={{ zIndex: 1100 }}
                              options={tagList.map(item => ({
                                label: item.name,
                                value: item.id
                              }))}
                              {...field}
                              onChange={value => {
                                onTagChange(field, value);
                              }}
                              loading={isCreatingTag}
                              filterOption={(input, option) =>
                                option?.label
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          )}
                        />
                      </Col> */}
                      {/* custom search tag */}
                      <Col xs={12} className="mt-3">
                        <Controller
                          name="tags"
                          control={control}
                          render={({ field }) => (
                            <CustomSelectWithAdd
                              style={{
                                width: '100%'
                              }}
                              placeholder="Search or create tags"
                              inputPlaceholder="Please enter tag name"
                              options={tagList.map(item => ({
                                label: item.name,
                                value: item.id
                              }))}
                              field={field}
                              handleAdd={handleCreateTag}
                              loading={isCreatingTag}
                            />
                          )}
                        />
                      </Col>
                      <Col xs={12} className="mt-5">
                        <Form.Check
                          name="updateExisting"
                          label={<b>Update existing imported subscribers</b>}
                          type="checkbox"
                          id="updateExisting"
                          {...register('updateExisting')}
                        />
                        Their information will be replaced with data from your
                        import; duplicates won't be imported otherwise.
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="text-center d-block border-0 py-1 mx-30">
          {tab === 'manually' && (
            <>
              <Button
                id="close-modal"
                bsPrefix="btn_outline_md my-0"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                id="confirm-submit"
                bsPrefix="btn_default_md my-0 ms-4"
                type="submit"
              >
                Submit
              </Button>
            </>
          )}
          {tab === 'upload' && dataToImport?.length > 0 && !importResult && (
            <>
              <Button
                id="close-modal"
                bsPrefix="btn_outline_md my-0"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                id="confirm-submit"
                bsPrefix="btn_default_md my-0 ms-4"
                type="submit"
              >
                Import
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
AddSubscriberModal.propTypes = {
  show: PropTypes.bool.isRequired,
  id: PropTypes.string,
  getData: PropTypes.func.isRequired
};

AddSubscriberModal.defaultProps = {
  id: null
};

export default AddSubscriberModal;
