import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.css';

const ExportIcon = ({ handleExport, className }) => {
  return (
    <div className={clsx(styles.iconContainer, className)}>
      <div
        className={styles.iconArea}
        onClick={handleExport}
        role="presentation"
      >
        <i className="icon_download" />
      </div>
    </div>
  );
};

ExportIcon.propTypes = {
  handleExport: PropTypes.func.isRequired,
  className: PropTypes.string
};

ExportIcon.defaultProps = {
  className: ''
};

export default ExportIcon;
