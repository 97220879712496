import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _, { filter, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import moment from 'moment';
import download from 'downloadjs';
import { Dropdown } from 'antd';

import Search from '../../../Search';
import styles from './style.module.css';
import { getToken } from '../../../../services/keyclockService';
import {
  DATE_FORMAT,
  DATETIMEFORMAT,
  REPORT_DATE_FORMAT,
  AD_CONTACT_FILTER_STATUS_OPTIONS,
  TIMEZONE,
  AD_CONTACT_TYPE
} from '../../../../constants';
import DataTable from '../../../common/DataTable';
import ExportIcon from '../../../common/ExportIcon';
import { useLoader } from '../../../../context/LoaderContext';
import TimeOption from '../../../common/TimeOption';

import {
  convertDateStringToUtcString,
  convertMomentToUtcString
} from '../../../../helpers/utils';
import commonStyles from '../../../common/styles.module.css';
import AddSubscriberModal from './AddSubscriberModal';
import { useModals } from '../../../common/ModalStack';
import StatusOption from '../../../common/StatusOption';
import MoreOption from '../../../common/MoreOption';
import { useCustomToast } from '../../../../context/ToastContext';
import { deleteAdSubscriber } from '../../../../services/userService';
import ConfirmModal from '../../../common/ConfirmModal';

const pageLimit = Number(process.env.REACT_APP_TABLE_PAGE_LIMIT);

const NewsletterSubscriber = () => {
  const navigate = useNavigate();
  const { setShowOverlayLoader } = useLoader();
  const { openModal, closeModal } = useModals();
  const { showToast } = useCustomToast();
  const [listData, setListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const previousFilterParamRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    startDate: null,
    endDate: null
  });

  const handleDelete = async id => {
    setShowOverlayLoader(true);
    const res = await deleteAdSubscriber(id);
    setShowOverlayLoader(false);
    if (res.error) {
      showToast('error', res.error);
      return;
    }
    showToast('success', 'Contact deleted successfully');
    // eslint-disable-next-line no-use-before-define
    getData();
  };

  const handleBulkDelete = async () => {
    setShowOverlayLoader(true);
    const failedIds = [];
    let hasSuccessRecords = false;
    const promises = selectedIds.map(async selectedId => {
      const res = await deleteAdSubscriber(selectedId);
      if (res.error) {
        failedIds.push(selectedId);
      } else {
        hasSuccessRecords = true;
      }
    });
    await Promise.all(promises);
    setShowOverlayLoader(false);
    closeModal();
    showToast(
      'success',
      `${hasSuccessRecords ? 'Contact(s) deleted successfully. ' : ''}${
        failedIds.length > 0
          ? `${failedIds.length} contact(s) failed to delete`
          : ''
      }`
    );
    setSelectedIds(failedIds);
    // eslint-disable-next-line no-use-before-define
    getData();
  };

  const onClickBulkDelete = () => {
    if (selectedIds.length === 0) {
      showToast('error', 'Please select at least one contact to delete');
      return;
    }
    openModal(ConfirmModal, {
      title: 'Delete Confirmation',
      message:
        'Are you sure you want to delete the selected subscribers? This action is irreversible!',
      handleProceed: handleBulkDelete
    });
  };

  const getDropdownItems = record => [
    {
      key: `edit-item-${record.id}`,
      label: 'Edit',
      onClick: () => {
        // eslint-disable-next-line no-use-before-define
        openModal(AddSubscriberModal, { id: record.id, getData });
        // navigate(`/subscriber/edit/${record.id}`);
      }
    },
    {
      key: `divider1-${record.id}`,
      type: 'divider'
    },
    {
      key: `delete-item-${record.id}`,
      label: 'Delete',
      onClick: () => {
        handleDelete(record.id);
      }
    }
  ];

  const listColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      sorter: true,
      disabled: true,
      fixed: 'left',
      selected: true
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: 150,
      sorter: true,
      align: 'center',
      selected: true
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: 150,
      sorter: true,
      align: 'center',
      selected: true
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      width: 150,
      sorter: true,
      align: 'center',
      selected: true
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: 150,
      sorter: true,
      align: 'center',
      selected: true
    },
    {
      title: 'Phone',
      dataIndex: 'mobileNumber',
      width: 150,
      align: 'center',
      render: (text, record) =>
        text ? `${record.countryMobileCode}${text}` : '-',
      selected: true
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      width: 150,
      align: 'center',
      selected: true
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      sorter: true,
      align: 'center',
      render: text =>
        AD_CONTACT_TYPE.find(type => type.value === text)?.label ?? '-',
      selected: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      sorter: true,
      align: 'center',
      selected: true,
      render: text =>
        AD_CONTACT_FILTER_STATUS_OPTIONS.find(option => option.value === text)
          ?.label ?? '-'
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedTime',
      width: 200,
      sorter: true,
      align: 'center',
      render: text =>
        text ? moment.tz(text, TIMEZONE).format(DATETIMEFORMAT) : '-',
      selected: true
    },
    {
      width: 100,
      className: 'text-center',
      fixed: 'right',
      render: (text, record) => {
        if (record.type === 'Imported_User') {
          return (
            <Dropdown
              menu={{ items: getDropdownItems(record) }}
              trigger={['click']}
            >
              <Button className="btn_default_sm" id="dropdown-basic">
                Select
              </Button>
            </Dropdown>
          );
        }
        return '';
      },
      notExportable: true
    }
  ];

  const [filterStatus, setFilterStatus] = useState({
    value: 'ALL',
    label: 'All Status'
  });
  const [filterTime, setFilterTime] = useState({
    value: 'all',
    label: 'All Time'
  });
  const [filterMore, setFilterMore] = useState({
    columns: listColumns
  });
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [showTimeFilter, setShowTimeFilter] = useState(false);
  const [showMoreFilter, setShowMoreFilter] = useState(false);

  const getDefaultFilterParam = () => {
    const fromDate = moment().subtract(1, 'years').format(REPORT_DATE_FORMAT);
    const toDate = moment().endOf('day').format(REPORT_DATE_FORMAT);

    return {
      pageSize: pageLimit,
      pageNo: 0,
      fromDate,
      toDate,
      sortField: 'updatedTime',
      sortDirection: 'DESC',
      status: 'ALL',
      columns: listColumns
    };
  };

  const [filterParam, setFilterParam] = useState(getDefaultFilterParam());

  const buildQuery = columns => {
    const fields = columns.join('\n');
    return gql`
      query GetAdSubscriberList(
        $fromDate: String!
        $toDate: String!
        $pageNo: Int
        $pageSize: Int
        $sortField: String
        $sortDirection: String
        $search: String
        $status: String
      ) {
        getAdSubscriberList(
          input: {
            fromDate: $fromDate
            toDate: $toDate
            pageNo: $pageNo
            pageSize: $pageSize
            sortField: $sortField
            sortDirection: $sortDirection
            search: $search,
            status: $status
          }
        ) {
          page {
            size
            number
            totalElements
            totalPages
          }
          content {
            id
            countryMobileCode
            ${fields}
          }
        }
      }
    `;
  };

  const [getAdSubscriberList] = useLazyQuery(
    buildQuery(
      filterParam.columns.filter(col => col.selected).map(col => col.dataIndex)
    ),
    {
      context: {
        headers: {
          authorization: `Bearer ${getToken(window.KEYCLOAKINSTANCE)}`
        }
      }
    }
  );

  const getData = async () => {
    setIsLoading(true);
    const { data, error } = await getAdSubscriberList({
      variables: filterParam,
      fetchPolicy: 'no-cache'
    });
    setIsLoading(false);
    if (error) {
      toast.error('Error retrieving data');
      return;
    }
    setTotalCount(data.getAdSubscriberList.page.totalElements);
    setListData(data.getAdSubscriberList.content);
  };

  // Filter section
  const resetData = () => {
    setFilterStatus({
      value: 'ALL',
      label: 'All Status'
    });
    setFilterTime({
      value: 'all',
      label: 'All Time'
    });
    setSearchCriteria({ startDate: null, endDate: null });
    setShowStatusFilter(false);
    setShowTimeFilter(false);
    setFilterParam(prevState => {
      return { ...prevState, ...getDefaultFilterParam() };
    });
  };

  const onTimeStatusChange = item => {
    setSearchCriteria({ startDate: null, endDate: null });
    setFilterTime(item);
    if (item.value === 'custom') {
      return;
    }
    setShowTimeFilter(false);
    let startDate = null;
    switch (item.value) {
      case 'all':
        startDate = moment('2020-01-01', DATE_FORMAT);
        break;
      case 'lastWeek':
        startDate = moment().subtract(7, 'days');
        break;
      case 'last1Month':
        startDate = moment().subtract(1, 'months');
        break;
      case 'last3Months':
        startDate = moment().subtract(3, 'months');
        break;
      case 'last1Year':
        startDate = moment().subtract(1, 'years');
        break;
      default:
    }
    setFilterParam(prevState => {
      return {
        ...prevState,
        fromDate: startDate.format(REPORT_DATE_FORMAT),
        toDate: getDefaultFilterParam().toDate
      };
    });
  };

  const onChangeStartDate = (date, dateString) => {
    setSearchCriteria(prevState => {
      return { ...prevState, startDate: date ? dateString : null };
    });
  };

  const onChangeEndDate = (date, dateString) => {
    setSearchCriteria(prevState => {
      return { ...prevState, endDate: date ? dateString : null };
    });
  };

  const handleSearchByDates = () => {
    if (searchCriteria.startDate && searchCriteria.endDate) {
      const startDate = convertDateStringToUtcString(
        searchCriteria.startDate,
        DATE_FORMAT,
        'Asia/Singapore'
      );
      const endDate = convertMomentToUtcString(
        moment(searchCriteria.endDate, DATE_FORMAT).endOf('day'),
        'Asia/Singapore'
      );
      setFilterParam(prevState => {
        return { ...prevState, fromDate: startDate, toDate: endDate };
      });
    } else if (!searchCriteria.startDate && !searchCriteria.endDate) {
      const defaultParam = getDefaultFilterParam();
      setFilterParam(prevState => {
        return {
          ...prevState,
          fromDate: defaultParam.fromDate,
          toDate: defaultParam.toDate
        };
      });
    } else {
      toast.error('Please specify the search time range');
      return;
    }
    setShowTimeFilter(false);
  };

  const resetMoreFilter = () => {
    setFilterMore(prev => ({
      ...prev,
      columns: filterParam.columns
    }));
  };

  const applyMoreFilter = () => {
    if (filterMore.columns.filter(col => col.selected).length === 0) {
      showToast('error', 'Please select at least one column to export');
      return;
    }
    setFilterParam(prevState => {
      return {
        ...prevState,
        columns: filterMore.columns
      };
    });
    setShowMoreFilter(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilterParam(prevState => {
      let sortDirection = null;
      if (sorter.order) {
        sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC';
      }

      return {
        ...prevState,
        pageNo: pagination.current - 1,
        sortField: sorter.column?.dataIndex ?? null,
        sortDirection
      };
    });
  };

  const handleExport = async () => {
    setShowOverlayLoader(true);
    const { pageNo, pageSize, ...rest } = filterParam;
    const { data, error } = await getAdSubscriberList({
      variables: rest,
      fetchPolicy: 'no-cache'
    });
    setShowOverlayLoader(false);
    if (error) {
      toast.error(error);
      return;
    }
    const headers = filterParam.columns
      .filter(col => col.selected)
      .map(fCol => fCol.title);
    const rows = data.getAdSubscriberList.content.map(row =>
      filterParam.columns
        .filter(col => col.selected)
        .map(fCol =>
          fCol.render
            ? fCol.render(row[fCol.dataIndex], row)
            : row[fCol.dataIndex]
        )
        .join(',')
    );
    const csvContent = [headers, ...rows].join('\n');

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Subscription_List_${moment().format('DDMMYYYY')}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (previousFilterParamRef.current) {
      const isFilterParamChanged = !isEqual(
        previousFilterParamRef.current,
        filterParam
      );
      if (isFilterParamChanged) {
        getData();
      }
    }
    previousFilterParamRef.current = filterParam;
  }, [filterParam]);

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = value => {
    setFilterParam(prevState => {
      return { ...prevState, search: value };
    });
  };

  const debounceSearch = _.debounce(handleSearch, 500);

  const handleAddSubscriber = () => {
    openModal(AddSubscriberModal, {
      getData
    });
  };

  const getFilterSection = () => {
    return (
      <div className={commonStyles.filterPane}>
        <span className="bodybold">Filter</span>
        <div className={commonStyles.filterDropDownContainer}>
          {/* Status Filter */}
          <StatusOption
            options={AD_CONTACT_FILTER_STATUS_OPTIONS}
            showStatusFilter={showStatusFilter}
            setShowStatusFilter={setShowStatusFilter}
            filterStatus={filterStatus}
            handleFilterStatusChange={item => {
              setFilterStatus(item);
              setFilterParam(prevState => {
                return { ...prevState, status: item.value };
              });
            }}
            isLoading={isLoading}
            handleShowOverlay={() => {
              setShowStatusFilter(prev => !prev);
              setShowTimeFilter(false);
            }}
            width="145px"
          />
          {/* Time Filter */}
          <TimeOption
            showTimeFilter={showTimeFilter}
            setShowTimeFilter={setShowTimeFilter}
            filterTime={filterTime}
            searchCriteria={searchCriteria}
            isLoading={isLoading}
            handleShowOverlay={() => {
              setShowTimeFilter(prev => !prev);
              setShowStatusFilter(false);
            }}
            onTimeStatusChange={onTimeStatusChange}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            handleSearchByDates={handleSearchByDates}
          />
        </div>
        <div className={commonStyles.filterDropDownContainer}>
          <MoreOption
            showMoreFilter={showMoreFilter}
            setShowMoreFilter={setShowMoreFilter}
            isLoading={isLoading}
            handleShowOverlay={() => {
              setShowMoreFilter(!showMoreFilter);
              setShowTimeFilter(false);
            }}
            filterMore={filterMore}
            setFilterMore={setFilterMore}
            resetMoreFilter={resetMoreFilter}
            applyMoreFilter={applyMoreFilter}
          />
        </div>
        <div className="clickable" onClick={resetData} role="presentation">
          Clear
        </div>
      </div>
    );
  };

  return (
    <div>
      <Row className="mb-8">
        <Col>
          <h3 className="tem_header">Ad Subscribers</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="bodybig">
          <p>Manage all newsletter ad subscribers</p>
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col xs={12} xl={3} xxl={4} className="mt-6 d-flex gap-3">
          <div className="d-flex flex-fill">
            <Search
              className="w-75 w-xl-100"
              savedQuery={filterParam.search}
              setSavedQuery={debounceSearch}
            />
          </div>
        </Col>
        <Col xs={12} xl={9} xxl={8} className="mt-6 d-flex gap-3">
          {getFilterSection()}
          <ExportIcon handleExport={handleExport} />
        </Col>
      </Row>
      <div className="mt-10">
        <div className="d-flex justify-content-between mb-3">
          <div className={`${commonStyles.iconGroup} pe-sm-2 ms-5`}>
            <i
              className="icon_remove"
              title="Delete"
              aria-hidden="true"
              onClick={onClickBulkDelete}
            />
          </div>

          <Button
            id="create-subscriber"
            bsPrefix="btn_outline_gray_sm btn_square_md"
            onClick={handleAddSubscriber}
          >
            Add Subscriber
          </Button>
        </div>
        <DataTable
          rowSelection={{
            selectedIds,
            onChange: newSelectedRowKeys => {
              setSelectedIds(newSelectedRowKeys);
            }
          }}
          data={listData}
          columns={filterParam.columns.filter(
            col => col.selected || col.notExportable
          )}
          pageNo={filterParam.pageNo + 1}
          pageSize={filterParam.pageSize}
          currentFilter={filterParam.sortField}
          filterDirection={filterParam.sortDirection}
          total={totalCount}
          handleTableChange={handleTableChange}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
export default NewsletterSubscriber;
