import { get, httpDelete, post, put } from './httpService';

const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

const getPlanList = async () => {
  const response = await get(`${apiUrl}/admin/plans`);
  return response;
};
const getPlanDetail = async id => {
  const response = await get(`${apiUrl}/admin/plans/${id}`);
  return response;
};
const createPlans = async params => {
  try {
    const response = await post(`${apiUrl}/admin/plans`, params);
    return response;
  } catch (error) {
    return false;
  }
};
const updatePlans = async (params, id) => {
  try {
    const response = await put(`${apiUrl}/admin/plans/${id}`, params);
    return response;
  } catch (error) {
    return false;
  }
};
const deletePlans = async id => {
  try {
    const response = await httpDelete(`${apiUrl}/admin/plans/${id}`);
    return response;
  } catch (error) {
    return false;
  }
};
const searchPlansByName = async name => {
  const response = await get(`${apiUrl}/admin/plans/search?name=${name}`);
  return response;
};

export {
  getPlanList,
  getPlanDetail,
  createPlans,
  updatePlans,
  deletePlans,
  searchPlansByName
};
