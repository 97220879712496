import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import Search from '../../../Search';
import styles from './style.module.css';
import MyDropdownBtn from '../../../Management/DropdownBtn';
import {
  getNotificationList,
  searchNotificationByName,
  deleteNotification
} from '../../../../services/notification';
import NewLoader from '../../../common/NewLoader';
import {
  NotificationChannelOptions,
  NotificationTypeOptions,
  NotificationStatus
} from '../../../../helpers/maps';
import { DATETIMEFORMAT, TIMEZONE } from '../../../../constants';

const Notification = () => {
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_LIMIT;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exclusiveStartKey, setExclusiveStartKey] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const navigate = useNavigate();
  const { ref, inView } = useInView();

  const getListData = async () => {
    const result = await getNotificationList({ limit: pageLimit });
    if (result) {
      setTableData(result.notifications);
      setExclusiveStartKey(result.lastEvaluatedKey);
      setIsLoading(false);
    }
  };

  const getMoreData = async () => {
    const result = await getNotificationList({
      exclusiveStartKey,
      limit: pageLimit,
      query: searchKeyword
    });
    if (result) {
      setTableData([...tableData, ...result.notifications]);
      setExclusiveStartKey(result.lastEvaluatedKey);
    }
  };

  const searchData = async value => {
    const result = await searchNotificationByName(value);
    if (result) {
      setTableData(result.notifications);
      setExclusiveStartKey(result.lastEvaluatedKey);
      setIsLoading(false);
    }
  };

  const handleCreateNotification = () => {
    navigate('/notification/create');
  };

  const handleEdit = data => {
    navigate(`/notification/edit/${data.id}`);
  };

  const handleView = data => {
    navigate(`/notification/view/${data.id}`);
  };

  const handleDelete = async data => {
    const result = await deleteNotification(data.id);
    if (result) {
      toast.success('Successfully deleted');
      setTableData(tableData.filter(item => item.id !== data.id));
    }
  };

  // const debouceSearch = _.debounce(handleSearch, 500);
  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    if (inView && exclusiveStartKey) {
      getMoreData();
    }
  }, [inView]);

  const handleClickSearch = value => {
    setSearchKeyword(value);
    searchData(value);
  };

  return (
    <div>
      <Row className="mb-8">
        <Col>
          <h3 className="tem_header">Notification Types</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="bodybig">
          <p>Generate various types of emails and notifications.</p>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="mt-6">
          <Button bsPrefix="btn_default_xl" onClick={handleCreateNotification}>
            Create Notification
          </Button>
        </Col>
        <Col lg={5} md={12} className="mt-6">
          <Search
            handleClickSearch={value => {
              setIsLoading(true);
              handleClickSearch(value.trim());
            }}
          />
        </Col>
      </Row>
      {isLoading ? (
        <NewLoader className="mt-20" />
      ) : (
        <div className="container-fluid mt-10 px-0">
          <Row className="table_header mx-0 bodybold">
            <Col xl="2" xs="6">
              Content
            </Col>
            <Col xl="2" xs="2">
              Channel
            </Col>
            {/* <Col xl="1" xs="2">
              Type
            </Col> */}
            <Col xl="2" xs="2">
              Status
            </Col>
            <Col xl="2" className="d-none d-xl-block">
              Creation Date
            </Col>
            <Col xl="2" className="d-none d-xl-block">
              Publish Date
            </Col>
            <Col xs="2" />
          </Row>
          {tableData.length > 0 || exclusiveStartKey ? (
            <>
              <InfiniteScroll
                dataLength={tableData.length}
                next={getMoreData}
                hasMore={exclusiveStartKey}
                // loader={<span>Loading..</span>}
                className={clsx({ [styles.tableHeight]: tableData.length > 0 })}
              >
                {tableData.map((item, index) => {
                  return (
                    <Row className={`${styles.table_row} mx-0`} key={index}>
                      <Col xl="2" xs="6">
                        {/* {item.id} */}
                        {item.subject?.length > 0
                          ? item.subject
                          : item.body?.substring(0, 70)}
                      </Col>
                      <Col xl="2" xs="2">
                        {
                          NotificationChannelOptions.find(
                            i => i.value === item.channel
                          )?.label
                        }
                      </Col>
                      {/* <Col xl="1" xs="2">
                      {
                        NotificationTypeOptions.find(i => i.value === item.type)
                          ?.label
                      }
                    </Col> */}
                      <Col xl="2" xs="2">
                        {NotificationStatus[item.status]}
                      </Col>
                      <Col xl="2" className="d-none d-xl-block">
                        {moment
                          .tz(item.createdAt, TIMEZONE)
                          .format(DATETIMEFORMAT)}
                      </Col>
                      <Col xl="2" className="d-none d-xl-block">
                        {moment
                          .tz(item.sendAt, TIMEZONE)
                          .format(DATETIMEFORMAT)}
                      </Col>
                      <Col xs="2" className="text-center">
                        <MyDropdownBtn
                          data={item}
                          label="notification"
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                          handleView={handleView}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </InfiniteScroll>
              <div ref={ref} />
              {tableData.length === 0 && exclusiveStartKey && (
                <NewLoader className="mt-20" />
              )}
            </>
          ) : (
            <Row>
              <Col className="text-center pt-20 default-color-light bodylarge">
                You don't have any data
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};
export default Notification;
