import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Private from '../routing/Private';
import LandingPage from '../components/pages/LandingPage';
import AuthLayout from './AuthLayout';
import Management from '../components/pages/Mangemant';
import FullScreenModule from './fullScreenModule';
import NewFeature from '../components/pages/NewFeature';
import NewPlan from '../components/pages/NewPlan';
import NewNotification from '../components/pages/NewNotification';
import NewLoader from '../components/common/NewLoader';
import { useLoader } from '../context/LoaderContext';
import commonStyles from '../components/common/styles.module.css';
import NewTag from '../components/pages/NewTag';
import NewSubscriber from '../components/pages/NewSubscriber';

const Layout = ({ setKeycloak }) => {
  const { showOverlayLoader, showFullScreenLoader } = useLoader();

  return (
    <>
      <div
        className={clsx(commonStyles.loading, {
          [commonStyles.hide]: !showOverlayLoader
        })}
      >
        <NewLoader />
      </div>
      {showFullScreenLoader && <NewLoader className="whole-loading" />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* Authentication routes */}
        {/* Dashboard routes */}
        <Route
          element={
            <Private>
              <AuthLayout />
            </Private>
          }
        >
          <Route path="/dashboard" element={<Management />} />
          <Route path="/dashboard/:index" element={<Management />} />
        </Route>
        <Route
          element={
            <Private>
              <FullScreenModule />
            </Private>
          }
        >
          <Route path="/create-feature" element={<NewFeature />} />
          <Route path="/create-feature/:id" element={<NewFeature />} />
          <Route path="/create-plan" element={<NewPlan />} />
          <Route path="/create-plan/:id" element={<NewPlan />} />
          <Route path="/notification/:action" element={<NewNotification />} />
          <Route
            path="/notification/:action/:id"
            element={<NewNotification />}
          />
          {/* <Route path="/subscriber/:action/:id" element={<NewSubscriber />} /> */}
          <Route path="/tag/:action" element={<NewTag />} />
          <Route path="/tag/:action/:id" element={<NewTag />} />
        </Route>

        {/* Completed document routes */}
        {/* Owner: view triggered via completion email */}
      </Routes>
    </>
  );
};
Layout.propTypes = {
  setKeycloak: PropTypes.func.isRequired
};

export default Layout;
