import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _, { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import clsx from 'clsx';
import { Dropdown } from 'antd';

import Search from '../../../Search';
import styles from './style.module.css';
import commonStyles from '../../../common/styles.module.css';
import { DATETIMEFORMAT, TIMEZONE } from '../../../../constants';
import { useModals } from '../../../common/ModalStack';
import CreateTagModal from './CreateTagModal';

import DataTable from '../../../common/DataTable';
import { deleteTag, getAllTags } from '../../../../services/userService';
import { useCustomToast } from '../../../../context/ToastContext';
import { useLoader } from '../../../../context/LoaderContext';
import ConfirmModal from '../../../common/ConfirmModal';

const pageLimit = Number(process.env.REACT_APP_TABLE_PAGE_LIMIT);

const Tag = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModals();
  const { showToast } = useCustomToast();
  const { setShowOverlayLoader } = useLoader();
  const [listData, setListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const previousFilterParamRef = useRef();

  const getDefaultFilterParam = () => {
    return {
      pageSize: pageLimit,
      pageNo: 0,
      sortField: 'updatedTime',
      sortDirection: 'DESC'
    };
  };

  const [filterParam, setFilterParam] = useState(getDefaultFilterParam());

  const getData = async () => {
    setListLoading(true);
    const response = await getAllTags(filterParam);
    setListData(response.itemList);
    setTotalCount(response.totalCount);
    setListLoading(false);
  };

  const handleCreateTag = () => {
    navigate('/tag/create');
  };

  const handleEdit = id => {
    navigate(`/tag/edit/${id}`);
  };

  const handleDelete = async id => {
    setShowOverlayLoader(true);
    const res = await deleteTag(id);
    setShowOverlayLoader(false);
    if (res.error) {
      showToast('error', res.error);
      return;
    }
    showToast('success', 'Tag deleted successfully');
    getData();
  };

  const handleBulkDelete = async () => {
    setShowOverlayLoader(true);
    const failedIds = [];
    let hasSuccessRecords = false;
    const promises = selectedIds.map(async selectedId => {
      const res = await deleteTag(selectedId);
      if (res.error) {
        failedIds.push(selectedId);
      } else {
        hasSuccessRecords = true;
      }
    });
    await Promise.all(promises);
    setShowOverlayLoader(false);
    closeModal();
    showToast(
      failedIds.length > 0 ? 'error' : 'success',
      `${hasSuccessRecords ? 'Tags deleted successfully. ' : ''}${
        failedIds.length > 0
          ? `${failedIds.length} tag(s) failed to delete`
          : ''
      }`
    );
    setSelectedIds(failedIds);
    // eslint-disable-next-line no-use-before-define
    getData();
  };

  const onClickBulkDelete = () => {
    if (selectedIds.length === 0) {
      showToast('error', 'Please select at least one tag to delete');
      return;
    }
    openModal(ConfirmModal, {
      title: 'Delete Confirmation',
      message:
        'Are you sure you want to delete the selected tags? This action is irreversible!',
      handleProceed: handleBulkDelete
    });
  };

  const getDropdownItems = record => [
    {
      key: `edit-item-${record.id}`,
      label: 'Edit',
      onClick: () => {
        handleEdit(record.id);
      }
    },
    {
      key: `divider1-${record.id}`,
      type: 'divider'
    },
    {
      key: `delete-item-${record.id}`,
      label: 'Delete',
      onClick: () => {
        handleDelete(record.id);
      }
    }
  ];

  const listColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      sorter: true,
      showSorterTooltip: false
    },
    {
      title: 'Type',
      dataIndex: 'type',
      className: 'text-center',
      width: 200,
      sorter: true,
      showSorterTooltip: false
    },
    {
      title: 'Created At',
      dataIndex: 'createdTime',
      width: 200,
      className: 'text-center',
      render: text =>
        text ? moment.tz(text, TIMEZONE).format(DATETIMEFORMAT) : '-'
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedTime',
      width: 200,
      className: 'text-center',
      sorter: true,
      showSorterTooltip: false,
      render: text =>
        text ? moment.tz(text, TIMEZONE).format(DATETIMEFORMAT) : '-'
    },
    {
      width: 100,
      className: 'text-center',
      fixed: 'right',
      render: (text, record) => (
        <Dropdown
          menu={{ items: getDropdownItems(record) }}
          trigger={['click']}
        >
          <Button className="btn_default_sm" id="dropdown-basic">
            Select
          </Button>
        </Dropdown>
      )
    }
  ];

  const searchData = async value => {
    // const result = await searchNotificationByName(value);
    // if (result) {
    //   setTableData(result.notifications);
    //   setListLoading(false);
    // }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilterParam(prevState => {
      let sortDirection = null;
      if (sorter.order) {
        sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC';
      }

      return {
        ...prevState,
        pageNo: pagination.current - 1,
        sortField: sorter.column?.dataIndex ?? null,
        sortDirection
      };
    });
  };

  useEffect(() => {
    if (previousFilterParamRef.current) {
      const isFilterParamChanged = !isEqual(
        previousFilterParamRef.current,
        filterParam
      );
      if (isFilterParamChanged) {
        // console.log(filterParam);
        getData();
      }
    }
    previousFilterParamRef.current = filterParam;
  }, [filterParam]);

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = value => {
    setFilterParam(prevState => {
      return { ...prevState, search: value };
    });
  };

  const debounceSearch = _.debounce(handleSearch, 500);

  return (
    <div>
      <Row className="mb-8">
        <Col>
          <h3 className="tem_header">Tags</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="bodybig">
          <p>List of tags that can be tied to entities eg. ad subscriber</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={4} className="mt-6 d-flex gap-3">
          <div className="d-flex flex-fill">
            <Search
              className="w-75 w-xl-100"
              savedQuery={filterParam.search}
              setSavedQuery={debounceSearch}
            />
          </div>
        </Col>
        <Col xs={12} xl={8} className="mt-6 d-flex gap-3">
          {/* {getFilterSection()}
          <ExportIcon handleExport={handleExport} /> */}
        </Col>
      </Row>
      <div className="mt-10">
        <div className="d-flex justify-content-between mb-2">
          <div className={`${commonStyles.iconGroup} ps-3`}>
            <i
              className="icon_remove"
              title="Delete"
              aria-hidden="true"
              onClick={onClickBulkDelete}
            />
          </div>
          <Button
            id="create-folder"
            bsPrefix="btn_outline_gray_sm btn_square_md"
            onClick={handleCreateTag}
          >
            New Tag
          </Button>
        </div>
        <DataTable
          rowSelection={{
            selectedIds,
            onChange: newSelectedRowKeys => {
              setSelectedIds(newSelectedRowKeys);
            }
          }}
          data={listData}
          columns={listColumns}
          pageNo={filterParam.pageNo + 1}
          pageSize={filterParam.pageSize}
          currentFilter={filterParam.sortField}
          filterDirection={filterParam.sortDirection}
          total={totalCount}
          handleTableChange={handleTableChange}
          isLoading={listLoading}
        />
      </div>
    </div>
  );
};
export default Tag;
