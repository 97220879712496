const CSVToJSON = (data = '', delimiter = ',') => {
  if (!data) {
    return null;
  }
  const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\r\n')
    .map(v => {
      const values = v.split(delimiter);
      return titles.reduce(
        // eslint-disable-next-line no-param-reassign, no-return-assign, prettier/prettier, no-sequences
        (obj, title, index) => ((obj[title.trim()] = values[index]), obj),
        {}
      );
    })
    .filter(row => {
      return Object.values(row).some(
        value => value !== undefined && value !== ''
      );
    });
};

// eslint-disable-next-line import/prefer-default-export
export { CSVToJSON };
