import React, { useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import DropdownRender from './DropdownRender';

// Move the dropdownRender function outside the component
const renderDropdown = (menu, name, setName, handleAdd, inputPlaceholder) => (
  <DropdownRender
    menu={menu}
    name={name}
    setName={setName}
    handleAdd={handleAdd}
    inputPlaceholder={inputPlaceholder}
  />
);

const CustomSelectWithAdd = ({
  style,
  mode,
  placeholder,
  inputPlaceholder,
  options,
  field,
  handleAdd,
  loading
}) => {
  const [name, setName] = useState('');

  return (
    <Select
      style={style}
      mode={mode}
      placeholder={placeholder}
      dropdownRender={menu =>
        renderDropdown(menu, name, setName, handleAdd, inputPlaceholder)
      }
      options={options}
      {...field}
      loading={loading}
      filterOption={(input, option) =>
        option?.label?.toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

CustomSelectWithAdd.propTypes = {
  style: PropTypes.shape({}),
  mode: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  field: PropTypes.shape({}).isRequired,
  handleAdd: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

CustomSelectWithAdd.defaultProps = {
  style: {},
  mode: 'multiple',
  loading: false,
  inputPlaceholder: 'Please enter item name'
};

export default CustomSelectWithAdd;
