/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Container
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import FiexedHeader from '../../Management/FixedHeader';
import styles from './style.module.css';
import FiexedFooter from '../../Management/FixedFooter';
import NewLoader from '../../common/NewLoader';
import { useCustomToast } from '../../../context/ToastContext';
import { createTag, editTag, getTagById } from '../../../services/userService';
import { useLoader } from '../../../context/LoaderContext';

const NewTag = () => {
  const { showToast } = useCustomToast();
  const { setShowOverlayLoader } = useLoader();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    clearErrors,
    watch
  } = useForm({ defaultValues: { name: '', type: 'AD_SUBSCRIPTION_TAG' } });
  const { action, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/dashboard/5');
  };

  const onSubmit = async data => {
    setShowOverlayLoader(true);
    let res = null;
    if (id) {
      res = await editTag({ ...data, id });
    } else {
      res = await createTag(data);
    }

    setShowOverlayLoader(false);
    if (res.error) {
      showToast('error', res.error);
      return;
    }
    showToast('success', `Tag ${id ? 'updated' : 'created'} successfully`);
    handleClose();
  };

  const getTag = async () => {
    const res = await getTagById(id);
    if (res.error) {
      showToast('error', res.error);
      return;
    }
    setValue('name', res.name);
    setValue('type', res.type);
    setIsLoading(false);
  };

  const getTitle = () => {
    switch (action) {
      case 'edit':
        return 'Edit Tag';
      case 'create':
        return 'Create Tag';
      case 'view':
        return 'View Tag';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (id) {
      getTag();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div>
      <FiexedHeader handleClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={`wrap mt-16 ${styles.content}`}>
          {isLoading ? (
            <NewLoader className="mt-20" />
          ) : (
            <>
              <Row>
                <Col>
                  <h2>{getTitle()}</h2>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12} className="mt-6">
                  <FloatingLabel controlId="name" label="Name">
                    <Form.Control
                      placeholder="Name*"
                      type="text"
                      autoComplete="off"
                      className={clsx('input_area', 'body', {
                        'is-invalid': errors?.name?.message
                      })}
                      {...register('name', {
                        required: 'Tag name is required',
                        maxLength: {
                          value: 100,
                          message:
                            'The maximum length cannot exceed 100 characters'
                        }
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      as={
                        <span className="text-danger d-inline-block ms-2 mt-1" />
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={12} className="mt-6">
                  <FloatingLabel controlId="type" label="Type">
                    <Form.Control
                      placeholder="Type*"
                      type="text"
                      autoComplete="off"
                      className={clsx('input_area', 'body', {
                        'is-invalid': errors?.name?.message
                      })}
                      disabled
                      {...register('type', {
                        required: 'Tag type is required',
                        maxLength: {
                          value: 100,
                          message:
                            'The maximum length cannot exceed 100 characters'
                        }
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="type"
                      as={
                        <span className="text-danger d-inline-block ms-2 mt-1" />
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          )}
        </Container>
        {action !== 'view' && (
          <FiexedFooter
            handleClose={handleClose}
            isDisabled={isLoading}
            isSubmitting={isSubmitting}
          />
        )}
      </form>
    </div>
  );
};

export default NewTag;
