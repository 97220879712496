import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import clsx from 'clsx';

import commonStyles from '../styles.module.css';
import DragDropContainer from './DragDropContainer';
import ColumnList from './ColumnList';

const MoreOption = ({
  showMoreFilter,
  setShowMoreFilter,
  isLoading,
  handleShowOverlay,
  filterMore,
  setFilterMore,
  resetMoreFilter,
  applyMoreFilter
}) => {
  const handleDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const reorderedColumns = Array.from(filterMore.columns);
    const [removed] = reorderedColumns.splice(source.index, 1);
    reorderedColumns.splice(destination.index, 0, removed);
    setFilterMore(prevState => ({
      ...prevState,
      columns: reorderedColumns
    }));
  };

  const moreFilterOverlay = () => {
    return (
      <Popover className={clsx(commonStyles.overlay, commonStyles.moreFilter)}>
        <Popover.Body>
          <Row>
            {/* <Col sx={12} md={6} style={{ width: '282px' }}>
              <div className={styles.moreFilterOverlaySectionTitle}>
                <b>Created By</b>
              </div>
              <div className="d-flex flex-column gap-2 my-3">
                {Object.values(REPORT_CREATED_BY_OPTIONS).map((item, index) => (
                  <Form.Check
                    key={index}
                    name="createdBy"
                    inline
                    checked={filterMore.createdBy === item.value}
                    onClick={() =>
                      setFilterMore(prevState => {
                        return { ...prevState, createdBy: item.value };
                      })
                    }
                    onChange={() => {}}
                    label={item.label}
                    value={item.value}
                    type="radio"
                    id={`inline-radio-${index}`}
                    className="mb-0"
                  />
                ))}
                <input
                  type="text"
                  className="input_area_sm mt-5"
                  style={{ padding: '3px 10px' }}
                  // value={value}
                  onChange={e => {
                    // if (e.target.value.length === 0) {
                    // }
                  }}
                />
              </div>
            </Col> */}
            <Col sx={12} md={6} className="px-0" style={{ width: '282px' }}>
              <div className={commonStyles.moreFilterOverlaySectionTitle}>
                <b>Export Columns</b>
                <span
                  className="small clickable"
                  onClick={() => {
                    setFilterMore(prevState => ({
                      ...prevState,
                      columns: prevState.columns
                        .filter(col => col.notExportable)
                        .map(column => ({
                          ...column,
                          selected: true
                        }))
                    }));
                  }}
                  role="presentation"
                >
                  Select All
                </span>
              </div>
              <div className={commonStyles.moreFilterColumnContainer}>
                <DragDropContainer handleDragEnd={handleDragEnd}>
                  <ColumnList
                    columns={filterMore.columns.filter(
                      col => !col.notExportable
                    )}
                    setFilterMore={setFilterMore}
                  />
                </DragDropContainer>
              </div>
            </Col>
          </Row>
          <Row
            className={clsx('mt-1 pt-3', commonStyles.moreFilterButtonGroup)}
          >
            <Col className="d-flex justify-content-center gap-2">
              <Button
                id="apply-filter-by"
                bsPrefix="btn_outline_sm"
                style={{ background: 'none' }}
                onClick={resetMoreFilter}
              >
                Reset
              </Button>
              <Button
                id="apply-filter-by"
                bsPrefix="btn_default_sm"
                onClick={applyMoreFilter}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    );
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        !event.target.closest(`.${commonStyles.overlay}`) &&
        !event.target.closest(`.${commonStyles.filterDropDown}`) &&
        !event.target.closest(
          `.${commonStyles.overlay}.${commonStyles.moreFilter}`
        )
      ) {
        setShowMoreFilter(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      show={showMoreFilter && !isLoading}
      placement="bottom"
      overlay={moreFilterOverlay()}
    >
      <div
        className={`bodybold ${commonStyles.filterDropDown}`}
        style={{ width: '90px' }}
        onClick={handleShowOverlay}
        aria-hidden
      >
        More
        <i className="icon_arrowDown_solid ms-2" />
      </div>
    </OverlayTrigger>
  );
};

MoreOption.propTypes = {
  showMoreFilter: PropTypes.bool.isRequired,
  setShowMoreFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleShowOverlay: PropTypes.func.isRequired,
  filterMore: PropTypes.shape({
    createdBy: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setFilterMore: PropTypes.func.isRequired,
  resetMoreFilter: PropTypes.func.isRequired,
  applyMoreFilter: PropTypes.func.isRequired
};

export default MoreOption;
