import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

const DragDropContainer = ({ children, handleDragEnd }) => {
  const containerRef = useRef(null);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="dragdrop-container" ref={containerRef}>
        {children}
      </div>
    </DragDropContext>
  );
};

DragDropContainer.propTypes = {
  children: PropTypes.node,
  handleDragEnd: PropTypes.func.isRequired
};

DragDropContainer.defaultProps = {
  children: null
};

export default DragDropContainer;
