import { get, httpDelete, post, put } from './httpService';

const apiUrl = process.env.REACT_APP_USER_API_URL;

const getSubscriberById = async id => {
  try {
    const response = await get(`${apiUrl}/user/ad-subscribe/${id}`);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const importAdSubscribers = async params => {
  try {
    const response = await post(`${apiUrl}/user/ad-subscribe/import`, params);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const editAdSubscriber = async params => {
  try {
    const response = await put(`${apiUrl}/user/ad-subscribe`, params);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const deleteAdSubscriber = async id => {
  try {
    const response = await httpDelete(`${apiUrl}/user/ad-subscribe/${id}`);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const getAllTags = async filterParam => {
  try {
    const url = new URL(`${apiUrl}/user/tags`);
    Object.entries(filterParam).forEach(([key, value]) => {
      if (value || value === 0) url.searchParams.append(key, value);
    });
    const response = await get(url.toString());
    return {
      itemList: response?.content ?? [],
      totalCount: response?.page?.totalElements ?? 0
    };
  } catch (err) {
    return {
      error:
        err?.response?.data.message ??
        err?.response?.data.error ??
        'Error retrieving tags'
    };
  }
};

const getTagById = async id => {
  try {
    const response = await get(`${apiUrl}/user/tag/${id}`);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const createTag = async params => {
  try {
    const response = await post(`${apiUrl}/user/tag`, params);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const editTag = async params => {
  try {
    const response = await put(`${apiUrl}/user/tag`, params);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

const deleteTag = async id => {
  try {
    const response = await httpDelete(`${apiUrl}/user/tag/${id}`);
    return response;
  } catch (err) {
    return {
      error: err?.response?.data.message ?? err?.response?.data.error
    };
  }
};

export {
  getSubscriberById,
  importAdSubscribers,
  editAdSubscriber,
  deleteAdSubscriber,
  getAllTags,
  getTagById,
  createTag,
  editTag,
  deleteTag
};
