import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import clsx from 'clsx';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import commonStyles from '../styles.module.css';
import { DATE_FORMAT, FILTER_TIME_OPTIONS } from '../../../constants';

const queryTimeOptionMonths = process.env.REACT_APP_QUERY_TIME_OPTION_MONTHS;
const queryTimeOptionMonthRange =
  process.env.REACT_APP_QUERY_TIME_OPTION_MONTH_RANGE;

const TimeOption = ({
  showTimeFilter,
  setShowTimeFilter,
  filterTime,
  searchCriteria,
  isLoading,
  handleShowOverlay,
  onTimeStatusChange,
  onChangeStartDate,
  onChangeEndDate,
  handleSearchByDates,
  width
}) => {
  const disabledDate = current => {
    // can not set days before 10 years ago
    if (searchCriteria.endDate) {
      const minDate = moment(searchCriteria.endDate).subtract(
        queryTimeOptionMonthRange,
        'months'
      );
      return (
        current < moment(minDate) ||
        current > moment(searchCriteria.endDate).add(1, 'days') ||
        current > moment()
      );
    }
    return (
      current < moment().subtract(queryTimeOptionMonths, 'months') ||
      current > moment()
    );
  };
  const disabledMaxDate = current => {
    // can not set days before 3 years ago
    if (searchCriteria.startDate) {
      const maximumDate = moment(searchCriteria.startDate).add(
        queryTimeOptionMonthRange,
        'months'
      );
      return (
        current < moment(searchCriteria.startDate) ||
        current > moment(maximumDate) ||
        current > moment()
      );
    }
    return (
      current < moment().subtract(queryTimeOptionMonths, 'months') ||
      current > moment()
    );
  };

  const timeFilterOverlay = () => {
    return (
      <Popover className={commonStyles.overlay}>
        <Popover.Body>
          <div className={commonStyles.overlayContent}>
            {FILTER_TIME_OPTIONS.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.value === 'custom' && <hr />}
                  <div
                    className={clsx('clickable', commonStyles.optionItem, {
                      [commonStyles.selectedItem]:
                        item?.value === filterTime?.value,
                      customDate: item.value === 'custom'
                    })}
                    onClick={() => {
                      onTimeStatusChange(item);
                    }}
                    aria-hidden
                  >
                    {item.label}
                  </div>
                </React.Fragment>
              );
            })}
            {filterTime?.value === 'custom' && (
              <div className={commonStyles.customDatePickerContainer}>
                <DatePicker
                  className="custom-datepicker"
                  popupClassName="custom-date-dropdown"
                  placeholder="From"
                  onChange={onChangeStartDate}
                  inputReadOnly
                  defaultValue={
                    searchCriteria.startDate
                      ? dayjs(searchCriteria.startDate, DATE_FORMAT)
                      : null
                  }
                  popupStyle={{ zIndex: 1100 }}
                  disabledDate={disabledDate}
                  format={DATE_FORMAT}
                />
                <DatePicker
                  placeholder="To"
                  onChange={onChangeEndDate}
                  inputReadOnly
                  defaultValue={
                    searchCriteria.endDate
                      ? dayjs(searchCriteria.endDate, DATE_FORMAT)
                      : null
                  }
                  popupStyle={{ zIndex: 1100 }}
                  className="custom-datepicker ms-1"
                  popupClassName="custom-date-dropdown"
                  disabledDate={disabledMaxDate}
                  format={DATE_FORMAT}
                />
                <i
                  className="clickable icon_send_white ms-2"
                  onClick={handleSearchByDates}
                  aria-hidden
                />
              </div>
            )}
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        !event.target.closest(`.${commonStyles.overlay}`) &&
        !event.target.closest(`.${commonStyles.filterDropDown}`) &&
        !event.target.closest('.customDate') &&
        !event.target.closest('.custom-datepicker') &&
        !event.target.closest('.custom-date-dropdown') &&
        !event.target.closest('.icon_send_white')
      ) {
        setShowTimeFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      show={showTimeFilter && !isLoading}
      placement="bottom-end"
      overlay={timeFilterOverlay()}
    >
      <div
        className={`bodybold ${commonStyles.filterDropDown}`}
        style={{ width }}
        onClick={handleShowOverlay}
        aria-hidden
      >
        {filterTime?.label}
        <i className="icon_arrowDown_solid ms-2" />
      </div>
    </OverlayTrigger>
  );
};

TimeOption.propTypes = {
  showTimeFilter: PropTypes.bool.isRequired,
  setShowTimeFilter: PropTypes.func.isRequired,
  filterTime: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  searchCriteria: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleShowOverlay: PropTypes.func.isRequired,
  onTimeStatusChange: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  handleSearchByDates: PropTypes.func.isRequired,
  width: PropTypes.string
};

TimeOption.defaultProps = {
  width: '155px'
};

export default TimeOption;
