import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Search from '../../../Search';
import styles from './style.module.css';
import FeatureTable from '../../../Management/FeatureTable';
import {
  getFeatureList,
  searchFeatureByName
} from '../../../../services/feature';
import NewLoader from '../../../common/NewLoader';

const Feature = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const searchData = async value => {
    const result = await searchFeatureByName(value);
    if (result) {
      setTableData(result);
    }
  };

  const handleCreateFeature = () => {
    navigate('/create-feature');
  };

  const getFeatureListData = async () => {
    setLoading(true);
    const result = await getFeatureList();
    if (result) {
      const newData = result.sort((a, b) => {
        return b.updatedAt < a.updatedAt ? -1 : 1;
      });
      setTableData(newData);
      setLoading(false);
    }
  };
  const handleSearch = value => {
    if (value) {
      searchData(value);
    } else {
      getFeatureListData();
    }
  };
  const debouceSearch = _.debounce(handleSearch, 500);
  useEffect(() => {
    getFeatureListData();
  }, []);
  return (
    <div>
      <Row className="mb-8">
        <Col>
          <h3 className="tem_header">Feature</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="bodybig">
          <p>Create and manage various features.</p>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="mt-6">
          <Button bsPrefix="btn_default_xl" onClick={handleCreateFeature}>
            Create Feature
          </Button>
        </Col>
        <Col lg={5} md={12} className="mt-6 mb-10">
          <Search handleClickSearch={debouceSearch} />
        </Col>
      </Row>
      {loading ? (
        <NewLoader className="mt-20" />
      ) : (
        <FeatureTable tableList={tableData} getListData={getFeatureListData} />
      )}
    </div>
  );
};
export default Feature;
