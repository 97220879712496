import { Divider, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

const DropdownRender = ({
  menu,
  name,
  setName,
  handleAdd,
  inputPlaceholder
}) => (
  <>
    {menu}
    <Divider
      style={{
        margin: '8px 0'
      }}
    />
    <div
      className="d-flex justify-content-between gap-2"
      style={{
        padding: '0 15px 4px'
      }}
    >
      <Input
        placeholder={inputPlaceholder}
        // ref={inputRef}
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
      />
      <Button
        className="btn_default_sm"
        id="dropdown-basic"
        onClick={async () => {
          await handleAdd(name);
          setName('');
        }}
      >
        Add
      </Button>
    </div>
  </>
);

DropdownRender.propTypes = {
  menu: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  inputPlaceholder: PropTypes.string.isRequired
};

export default DropdownRender;
