import Container from 'react-bootstrap/Container';
import React, { useEffect } from 'react';
import TopBar from '../../nav/TopBar/index';
import { useModals } from '../../common/ModalStack';

const LandingPage = () => {
  const { closeAllModals } = useModals();
  useEffect(() => {
    closeAllModals();
  }, []);
  return (
    <Container fluid className="p-0">
      <TopBar />
      <Container fluid className="wrap p-0 mt76">
        <h2 className="mt-20 pt-20 text-center">TezSign Super Admin</h2>
      </Container>
    </Container>
  );
};
export default LandingPage;
