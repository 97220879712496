import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Form } from 'react-bootstrap';
import commonStyles from '../styles.module.css';

const ColumnListItem = ({ index, item, setFilterMore }) => {
  const { dataIndex, title } = item;
  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'grab',
    transform: isDragging ? 'rotate(-2deg)' : '',
    zIndex: isDragging ? 9999 : 'auto',
    position: isDragging ? 'relative' : 'static'
  });

  return (
    <Draggable
      draggableId={dataIndex}
      index={index}
      // isDragDisabled={getIsDragDisabled()}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            position: 'static'
          }}
          className="list-item m-0"
        >
          <Card
            style={{
              ...getItemStyle(snapshot.isDragging)
            }}
            className={commonStyles.columnItem}
          >
            <Card.Body className="p-0">
              <div className="align-items-center d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <i className="icon_list_white me-1" />
                  {title}
                </div>
                <Form.Check
                  // name="frequency"
                  label=""
                  type="checkbox"
                  id="column-selection"
                  className="m-0"
                  checked={item.selected}
                  disabled={item.disabled}
                  onChange={e => {
                    setFilterMore(prev => {
                      const newFilterMore = _.cloneDeep(prev);
                      newFilterMore.columns[index].selected = e.target.checked;
                      return newFilterMore;
                    });
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

ColumnListItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    dataIndex: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool
  }),
  index: PropTypes.number,
  setFilterMore: PropTypes.func.isRequired
};

ColumnListItem.defaultProps = {
  item: null,
  index: null
};

export default ColumnListItem;
