import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const TOAST_ID = 'toast_id';

  const showToast = useCallback((type, message, options = {}) => {
    const finalOptions = {
      autoClose: 3000, // Default autoClose time
      ...options // Override if any options are provided
    };

    const toastTypeMapping = {
      success: toast.TYPE.SUCCESS,
      error: toast.TYPE.ERROR,
      info: toast.TYPE.INFO
    };

    const mappedType = toastTypeMapping[type] || toast.TYPE.DEFAULT;
    if (toast.isActive(TOAST_ID)) {
      toast.update(TOAST_ID, {
        render: message,
        type: mappedType,
        ...finalOptions
      });
    } else {
      if (type === 'success') {
        toast.success(message, { ...finalOptions, toastId: TOAST_ID });
      }
      if (type === 'error') {
        toast.error(message, { ...finalOptions, toastId: TOAST_ID });
      }
      if (type === 'info') {
        toast.info(message, { ...finalOptions, toastId: TOAST_ID });
      }
    }
  }, []);

  const forceCloseToast = useCallback(() => {
    return new Promise(resolve => {
      if (toast.isActive(TOAST_ID)) {
        toast.dismiss(TOAST_ID); // Dismiss the active toast
        setTimeout(() => {
          resolve();
        }, 200); // custom delay to ensure that toast UI disappears from component before resolving.
      } else {
        resolve(); // If the toast is not active, resolve immediately
      }
    });
  }, []);

  const contextValue = useMemo(
    () => ({ showToast, forceCloseToast }),
    [showToast, forceCloseToast]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useCustomToast = () => useContext(ToastContext);
