import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import uploadicon from '../../../../../../assets/img/icons/upload-cloud-icon.svg';
import styles from './index.module.css';

const ImportDropZone = ({
  fileTips,
  buttonText = 'Choose File',
  fileSizeLimit
}) => {
  return (
    <div className={styles.container}>
      <div>
        <Image src={uploadicon} className={styles.image} />
      </div>
      <span>Drag & drop your file here</span>
      <span>OR</span>
      <button id="upload-file" type="button" className="btn_outline_md">
        {buttonText}
      </button>
      <input type="file" hidden />
      <span className="small">{fileTips}</span>

      {fileSizeLimit !== null && (
        <span
          className="small"
          style={{ display: 'flex', textAlign: 'center' }}
        >
          *Maximum file size limit is {fileSizeLimit}KB
        </span>
      )}
    </div>
  );
};
ImportDropZone.propTypes = {
  fileTips: PropTypes.string,
  buttonText: PropTypes.string,
  fileSizeLimit: PropTypes.number
};
ImportDropZone.defaultProps = {
  fileTips: '',
  buttonText: 'Choose File',
  fileSizeLimit: null
};
export default ImportDropZone;
