import React, { useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const ModalStackContext = React.createContext({});

const Modals = ({ stack }) => {
  return stack?.map((modal, index) => {
    if (!modal?.component) return null;
    return (
      <modal.component
        key={index}
        show={modal === stack[stack.length - 1]}
        {...modal.props}
      />
    );
  });
};

const ModalStack = ({ children, renderModals: ModalsComponent = Modals }) => {
  const [stack, setStack] = useState([]);

  const value = useMemo(() => {
    function pop(amount = 1) {
      return setStack(prev => [...prev].slice(0, prev.length - amount));
    }

    function dismissAll() {
      setStack([]);
    }

    function dismiss(amount) {
      if (stack.length === 1) {
        dismissAll();
      } else {
        pop(amount);
      }
    }

    function updateTopModal(newProps) {
      setStack(prev => {
        const updatedStack = [...prev];
        const topIndex = updatedStack.length - 1;

        if (updatedStack[topIndex]) {
          // Update the props of the topmost modal
          updatedStack[topIndex] = {
            ...updatedStack[topIndex],
            props: {
              ...updatedStack[topIndex].props,
              ...newProps
            }
          };
        }
        return updatedStack;
      });
    }

    return {
      stack,
      openModal: (component, props, options) => {
        setStack(prev => {
          let newStack = [...prev];

          if (options?.replace) {
            newStack = stack.slice(0, stack.length - 1);
          }

          return [...newStack, { component, props }];
        });
      },
      closeModal: () => dismiss(1),
      closeModals: dismiss,
      closeAllModals: dismissAll,
      updateTopModal
    };
  }, [stack]);

  return (
    <ModalStackContext.Provider value={value}>
      {children}
      <ModalsComponent {...value} />
    </ModalStackContext.Provider>
  );
};

const useModals = () => {
  return useContext(ModalStackContext);
};

ModalStack.propTypes = {
  children: PropTypes.node.isRequired,
  renderModals: PropTypes.func
};

ModalStack.defaultProps = {
  renderModals: Modals
};

export default ModalStack;

export { useModals };
