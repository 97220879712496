const Menus = [
  {
    Icon: 'icon_feature',
    title: 'Feature',
    id: 1
  },
  {
    Icon: 'icon_plan',
    title: 'Plan',
    id: 2
  },
  {
    Icon: 'icon_notification',
    title: 'Notification',
    id: 3
  },
  {
    Icon: 'icon_notification',
    title: 'Ad Subscribers',
    id: 4
  },
  {
    Icon: 'icon_notification',
    title: 'Tags',
    id: 5
  }
];

export default Menus;
