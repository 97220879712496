import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [showOverlayLoader, setShowOverlayLoader] = useState(false);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);

  const contextValue = useMemo(
    () => ({
      showOverlayLoader,
      setShowOverlayLoader,
      showFullScreenLoader,
      setShowFullScreenLoader
    }),
    [showOverlayLoader, showFullScreenLoader]
  );

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useLoader = () => useContext(LoaderContext);
