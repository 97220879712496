import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import ColumnListItem from './ColumnListItem';

const ColumnList = ({ columns, setFilterMore }) => {
  return (
    <Droppable droppableId="droppable">
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          id="container-1"
          className="list-container p-0 d-flex flex-column gap-1 my-3"
          style={{ overflow: 'hidden' }}
        >
          {columns.map((item, index) => (
            <ColumnListItem
              key={item.id ?? item.dataIndex}
              index={index}
              item={item}
              setFilterMore={setFilterMore}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

ColumnList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilterMore: PropTypes.func.isRequired
};

export default ColumnList;
