import Container from 'react-bootstrap/Container';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { useParams } from 'react-router-dom';
import Menus from '../../../helpers/sidebarMenusConfig';
import SidebarNav from '../../SidebarNav';
import { getUsername } from '../../../services/keyclockService';
import Feature from './Feature';
import Plan from './Plan';
import Notification from './Notification';
import NewsletterSubscriber from './NewsletterSubscriber';
import Tag from './Tag';

const Management = () => {
  const { index } = useParams();
  const [state, setState] = useState(index ? parseInt(index, 10) : 3);
  const { keycloak } = useKeycloak();

  const renderContent = stateNumber => {
    switch (stateNumber) {
      case 1:
        return <Feature />;
      case 2:
        return <Plan />;
      case 3:
        return <Notification />;
      case 4:
        return <NewsletterSubscriber />;
      case 5:
        return <Tag />;
      default:
        return null;
    }
  };
  useEffect(() => {}, []);
  return (
    <div className="pt45" style={{ minHeight: '85vh' }}>
      <Row className="mb-12">
        <Col>
          <h2 className="tem_header">
            Hi, {getUsername(keycloak)}{' '}
            <span className="btn_outline_sm align-middle">
              Super Admin Portal
            </span>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col lg="3" className="mb-20">
          <SidebarNav
            handleFilterByNav={id => {
              setState(id);
            }}
            state={state}
            menus={Menus}
          />
        </Col>
        <Col lg="9" className="mb-20">
          <div className="ms-8">{renderContent(state)}</div>
        </Col>
      </Row>
    </div>
  );
};
export default Management;
