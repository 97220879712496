import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NewLoader = ({ className }) => {
  return (
    <div className={className ? `${className} ${styles.wrap}` : styles.wrap}>
      <div className={styles.loop}>
        <svg viewBox="0 0 150 115">
          <path
            className={styles.path}
            fill="none"
            stroke="#4C4C4C"
            strokeWidth="4"
            d="M100.3,51.9c0-3.3-2.6-5.9-5.9-5.9H61.5L52,63.2c0,0,24.6,0.1,24.2,0.1c-0.3,0-11.6,19.9-11.6,19.9L47.3,83l13,22.6c3.6,4.1,7.9,4.7,11.5,0l25.9-45.2c0,0,2.6-4.7,2.6-6.1V51.9L100.3,51.9z"
            // fill="#F59700"
          />
          <path
            className={styles.path}
            fill="none"
            stroke="#F59700"
            strokeWidth="4"
            d="M32.7,31.7H17.9L3.1,5.9H42c2.9,0,4,2,2.4,4.5L32.7,31.7L32.7,31.7z"
            // fill="#F59700"
          />
          <path
            className={styles.path}
            fill="none"
            stroke="#F59700"
            strokeWidth="4"
            d="M39.9,27.8c0,0-5,9.5-8.3,15.7c-1.6,2.9-2.9,6.4-1.4,9.4c5,9.8,15.8,27.4,15.7,27.4s-1-3,1.5-9.1l22-39.5l0,0c0,0,30.4,0,44.8,0L129,5.9L56.1,5.7c-4.6,0-7,5.8-7,5.8C49.1,11.4,39.9,27.8,39.9,27.8L39.9,27.8z"
            // fill="#F59700"
          />
        </svg>
      </div>
      <span className={styles.blink_me}>Loading ...</span>
    </div>
  );
};
NewLoader.propTypes = {
  className: PropTypes.string
};
NewLoader.defaultProps = { className: '' };
export default NewLoader;
