import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

import styles from './style.module.css';
import { useCustomToast } from '../../../../context/ToastContext';
import { useLoader } from '../../../../context/LoaderContext';
import { useModals } from '../../../common/ModalStack';
import { createNotification } from '../../../../services/notification';

const SendTestEmailModal = ({ show, subject, body, type }) => {
  const { setShowOverlayLoader } = useLoader();
  const { showToast } = useCustomToast();
  const { openModal, closeModal } = useModals();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues: {
      email: '',
      message: ''
    }
  });

  const onSubmit = async data => {
    setShowOverlayLoader(true);
    const result = await createNotification({
      ...data,
      subject,
      body,
      channel: 1,
      type
    });
    setShowOverlayLoader(false);
    if (result) {
      showToast('success', 'Notification is created successfully');
      closeModal();
    }
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      dialogClassName="max-w650 custom-modal"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0 px-0 py-0">
          <Modal.Title className="w100 text-center">
            <h3 className="subtitle custom-modal-title">Send a Test Email</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-6 px-0 mx-30">
          <Row>
            <Col xs={12}>Send a test to</Col>
            <Col xs={12} className="mt-3">
              <FloatingLabel controlId="email" label="Email*">
                <Form.Control
                  size="sm"
                  placeholder="Email*"
                  name="email"
                  type="text"
                  autoComplete="off"
                  className={clsx('input_area', 'body', {
                    'is-invalid': errors.email
                  })}
                  {...register('email', {
                    required: 'Please enter email',
                    maxLength: {
                      value: 255,
                      message: 'Email should not exceed 255 characters'
                    },
                    pattern: {
                      value:
                        /^(([a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+),\s*)*([a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)$/,
                      message:
                        'Please enter valid email or comma-separated emails'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  as={<span className="text-danger d-inline-block fs-8 ms-2" />}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="small mt-1" style={{ color: '#9E9E9E' }}>
              Use commas to separate multiple emails.
            </Col>
            <Col xs={12} className="mt-10">
              Include instructions and a personal message (optional)
            </Col>
            <Col xs={12} className="mt-3">
              <FloatingLabel
                className="text-area-label"
                controlId="message"
                label="Your Message"
              >
                <Form.Control
                  size="sm"
                  as="textarea"
                  rows={8}
                  placeholder="Your Message"
                  name="message"
                  type="text"
                  autoComplete="off"
                  className={clsx(styles.message, 'input_area', 'body', {
                    'is-invalid': errors.message
                  })}
                  {...register('message', {
                    maxLength: {
                      value: 500,
                      message: 'Message should not exceed 500 characters'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="message"
                  as={<span className="text-danger d-inline-block fs-8 ms-2" />}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-center d-block border-0 py-1 mx-30">
          <Button
            id="close-modal"
            bsPrefix="btn_outline_md my-0"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            id="confirm-submit"
            bsPrefix="btn_default_md my-0 ms-4"
            type="submit"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
SendTestEmailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired
};

export default SendTestEmailModal;
