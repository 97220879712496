export const TEMPLATES_FORM_DROPZONE = 'templates.form.dropzone';
export const TEMPLATES_FORM_DOCUMENT_CASE_NAME =
  'templates.form.document.caseName';
export const TEMPLATES_FORM_DOCUMENT_CASE_DESCRIPTION =
  'templates.form.document.case.description';
export const TEMPLATES_FORM_RECIPIENT = 'templates.form.recipients';
export const TEMPLATES_FORM_RECIPIENT_NAME = 'name';
export const TEMPLATES_FORM_RECIPIENT_PHONE_PREFIX = 'phonePrefix';
export const TEMPLATES_FORM_RECIPIENT_PHONE = 'phone';
export const TEMPLATES_FORM_RECIPIENT_EMAIL = 'email';
export const TEMPLATES_FORM_RECIPIENT_REQUIRE_AUTH = 'requireAuth';
export const TEMPLATES_FORM_RECIPIENT_PRIVATE_MESSAGE = 'privateMessage';
export const TEMPLATES_FORM_RECIPIENT_MESSAGE_METHOD = 'messageMethod';

export const TEMPLATES_DASHBOARD_VIEW = 'templates-dashboard';
export const TEMPLATES_ADD_FILE_VIEW = 'templates-add-file';
export const TEMPLATES_ADD_RECIPIENTS_VIEW = 'templates-add-recipients';
export const TEMPLATES_CONFIGURATION_VIEW = 'templates-configuration';
export const TEMPLATES_REVIEW_VIEW = 'templates-review';
export const TEMPLATES_COMPLETED_VIEW = 'templates-completed';
export const TEMPLATES_PDF_VIEW = 'templates-pdf';

export const PDF_VIEWER_RECIPIENTS = [
  {
    label: 'Recipient 1',
    color: '🟡',
    rgb: { r: 255, g: 244, b: 222 }
  },
  {
    label: 'Recipient 2',
    color: '🟢',
    rgb: { r: 225, g: 248, b: 238 }
  },
  {
    label: 'Recipient 3',
    color: '🟣',
    rgb: { r: 215, g: 194, b: 255 }
  },
  {
    label: 'Recipient 4',
    color: '🔵',
    rgb: { r: 203, g: 237, b: 255 }
  },
  {
    label: 'Recipient 5',
    color: '🔴',
    rgb: { r: 255, g: 226, b: 229 }
  }
];
export const PDF_VIEWER_DRAG_TYPES = {
  TEXT_FIELD: 'text_field',
  SIGNATURE_FIELD: 'signature_field',
  DATE_SIGNED: 'date_signed'
};
export const PDF_VIEWER_DATA_TRANSFER_KEY = 'tezsign/annotation:';

export const TIMEZONE = 'Asia/Singapore';
export const DATETIMEFORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const REPORT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const AD_CONTACT_FILTER_STATUS_OPTIONS = [
  {
    value: 'ALL',
    label: 'All Status'
  },
  {
    value: 'Subscriber',
    label: 'Subscriber'
  },
  {
    value: 'UnSubscriber',
    label: 'Unsubscriber'
  }
];

export const AD_CONTACT_TYPE = [
  {
    value: 'Member',
    label: 'Member'
  },
  {
    value: 'Imported_User',
    label: 'Imported'
  },
  {
    value: 'Non_Member',
    label: 'Non-member'
  }
];

export const FILTER_TIME_OPTIONS = [
  {
    value: 'all',
    label: 'All Time'
  },
  {
    value: 'lastWeek',
    label: 'Last Week'
  },
  {
    value: 'last1Month',
    label: 'Last 1 Month'
  },
  {
    value: 'last3Months',
    label: 'Last 3 Months'
  },
  {
    value: 'last1Year',
    label: 'Last 1 Year'
  },
  {
    value: 'custom',
    label: 'Custom Date'
  }
];

export const SUNEDITORBUTTONLIST = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'fontColor', 'formatBlock'],
  [
    'bold',
    'underline',
    'italic',
    'strike',
    'subscript',
    'superscript',
    'removeFormat'
  ],
  ['fontColor', 'hiliteColor', 'textStyle'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'lineHeight'],
  ['table', 'math', 'link', 'image', 'video'],
  [
    'fullScreen',
    'showBlocks',
    'codeView',
    'preview',
    'template'
    // 'print',
    // 'save'
  ]
];

const s3Path =
  'https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024';

export const TEMPLATES = [
  {
    name: 'Product Launch',
    html: `<div style="background-color: #FFFFFF; padding: 30px;">
    <table>
      <tbody>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Logo Goes Here -->
          <div style="width:100%; float:left; padding: 10px 0;">
            <div style="float:left; padding-right:20px; border-right:1px solid #EEEEEE;" class="se-component se-image-container __se__float-">
            <figure style="width: 150px;">
              <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Launch/images/logo.svg" alt="" data-proportion="true" data-size="150px,39px" data-align="" data-index="0" data-file-name="logo.svg" data-file-size="0" origin-size="150,39" data-origin="150px,39px" style="width: 150px; height: 39px;">
            </figure>			  
            </div>
            <div style="float:left; padding-left:20px;" class="se-component se-image-container __se__float-">
            <figure style="width: 31px;">
              <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Launch/images/tezcert-ico.svg" alt="" data-proportion="true" data-size="31px,36px" data-align="" data-index="2" data-file-name="tezcert-ico.svg" data-file-size="0" origin-size="31,36" data-origin="31px,36px" style="width: 31px; height: 36px;">
            </figure>
            </div>
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Header Goes Here -->
          <div style="width: 100%" class="se-component se-image-container __se__float- __se__float-center">
          <figure>
            <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Launch/images/masthead.svg" width="100%" height="auto" alt="" data-proportion="true" data-size="530px,286px" data-align="" data-index="3" data-file-name="masthead.svg" data-file-size="0" origin-size="530,286" data-origin="530px,286px" style="width: 530px; height: 286px;">
          </figure>
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Title Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 30px; font-weight: 700; line-height: 42px; text-align: center; padding: 10px 0;">
            Elevate Trust with <br> TezCert&apos;s Verifiable Certificates
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Content Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 14px; line-height: 22px; text-align: center; padding: 10px 0;">
            Discover trust anew with TezCert, our advanced platform for seamless and reliable verifiable certificate issuance. Redefining credential management, TezCert ensures efficiency, transparency, and reliability, reshaping the future of digital certificates. Elevate trust with TezCert's innovation.
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="3">
              <div style="padding-right:20px;" class="se-component se-image-container __se__float-">
                <figure style="width: 280px;">
                  <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Launch/images/solutions.svg" alt="" data-proportion="true" data-size="280px,293px" data-align="" data-file-name="solutions.svg" data-file-size="0" origin-size="280,293" data-origin="280px,293px" style="width: 280px; height: 293px;" data-index="18">
                </figure>
              </div>
          </td>
          <td>
          <div style="text-align:left; padding-top:55px;">
            <p style="font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 16px; line-height: 21px; font-weight: 700;" align="left">Your Challenge</p>
            <p style="font-family: Manrope, sans-serif; color: rgb(76, 76, 76); font-size: 14px; line-height: 22px; padding: 10px 0px;" align="left"> Addressing issues like loss, manual processes, and environmental impact.</p>
    
          </div>
          </td>
        </tr>
        <tr>
          <td>
          <div style="text-align:left;">
            <p style="font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 16px; line-height: 21px; font-weight: 700;" align="left">Our Solution</p>
            <p style="font-family: Manrope, sans-serif; color: rgb(76, 76, 76); font-size: 14px; line-height: 22px; padding: 10px 0px;" align="left"> Spearheading blockchain-secured certificates, transforming traditional management.</p> 
          </div>	
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
            <div><br>
            </div>
          </td>
        </tr>	
        <tr>
          <td colspan="2" rowspan="1">
            <div><br>
            </div>
          </td>
        </tr>		  
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your CTA Button Goes Here -->
          <a href="https://tezcert.tezsign.com/" target="_blank">
            <div style="width: 250px; font-family: 'Manrope', sans-serif; font-size: 16px; line-height: 21px; color: #FFF; text-align: center; margin: 30px; padding: 15px 35px; background: #F59700; border-radius: 50px; margin: 0 auto;">Find Out More</div>
          </a>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
            <div><br>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Footer Content Goes Gere -->        
          <p style="font-size: 12px; line-height: 18px; text-align: center;"><a href="https://www.tezsign.com/privacy-policy" target="_blank" style="color: #4C4C4C;">Privacy Policy</a> | <a href="https://www.tezsign.com/terms-of-use" target="_blank" style="color: #4C4C4C;">Terms of Use</a> | <a href="https://www.tezsign.com/#contact" target="_blank" style="color: #4C4C4C;">Contact Us</a></p>
          <p style="font-size: 12px; line-height: 18px; text-align: center;">Copyright © 2024 TezSign. All rights reserved.</p>	
          </td>
        </tr>
      </tbody>
    </table>
    </div>    
    `
  },
  {
    name: 'Product Promotion',
    html: `<div style="background-color: #FFFFFF; padding: 30px;">
    <table>
      <tbody>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Logo Goes Here -->
          <div style="width:100%; float:left;">
            <div style="float:left; padding-right:20px;" class="se-component se-image-container __se__float-">
                      <figure>
                        <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Promotion/images/logo.svg" alt="" data-proportion="true" data-align="none" data-index="0" data-file-name="logo.svg" data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" style="">
                      </figure>		  
            </div>
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Header Goes Here -->
          <div style="width: 100%" class="se-component se-image-container __se__float- __se__float-center">
            <figure>
              <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Promotion/images/masthead.svg" width="100%" height="auto" alt="" data-proportion="true" data-align="center" data-file-name="masthead.svg" data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" style="" data-index="22">
            </figure>
                </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Title Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 30px; font-weight: 700; line-height: 42px; text-align: center; padding: 10px 0;">
            Get a 10% discount on <br> TezSign Apps Suites Bundle <br> Don&apos;t miss out!
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Content Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 16px; line-height: 22px; text-align: center; padding: 10px 0;">
            Empower your organization with a robust document solution that seamlessly embeds digital trust.
          </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Content Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-weight: 600; font-size: 14px; line-height: 22px; text-align: center; padding: 10px 0;">
            Apps Suite
          </div>
          </td>
        </tr>	 
        <tr>
          <td width="50%">
            <div class="se-component se-image-container __se__float-right">
              <figure style="width: 130px; height=auto;">
                  <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Promotion/images/tezsign.svg" width="130" height="auto" alt="" data-proportion="true" data-size="621px,36px" data-align="" data-file-name="tezsign.svg" data-file-size="0" origin-size="300,71" data-origin="621px,147px" style="width: 621px; height: 36px;" data-index="24">
              </figure>
            </div>
          </td>
          <td width="50%">
            <div class="se-component se-image-container __se__float-left">
              <figure style="width: 130px; height=auto;">
                  <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Promotion/images/tezcert.svg" width="130" height="auto" alt="" data-proportion="true" data-size="618px,30px" data-align="" data-file-name="tezcert.svg" data-file-size="0" origin-size="300,57" data-origin="618px,117px" style="width: 618px; height: 30px;" data-index="25">		  
              </figure>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Content Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 14px; line-height: 22px; text-align: center; padding: 10px 0;">
             Unlock savings with our exclusive offer!
          </div>
          </td>
        </tr>	
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Content Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 14px; line-height: 22px; text-align: center; padding: 10px 0;">
             Use promo code <span style="color: #DD0202; font-weight: 700;">TS015S</span> to enjoy a <span style="color: #DD0202; font-weight: 700;">10%</span> price reduction on the TezSign Apps Suites Bundle. Elevate your experience with powerful and efficient tools. Don't miss out on this limited-time opportunity!
          </div>
          </td>
        </tr>	  
          <tr>
          <td colspan="2" rowspan="1" width="100%">
          <!-- Your Header Goes Here -->
          <div class="se-component se-image-container __se__float-center">
            <figure>
                      <img src="https://tezsign-webapp-public.s3.ap-southeast-1.amazonaws.com/web/promo/Templates-012024/Product+Promotion/images/solutions.svg" width="326" height="137" alt="" data-proportion="true" data-align="center" data-file-name="solutions.svg" data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" style="" data-index="75" origin-size="326,136">
            </figure>
          </div>
          </td>
        </tr>
        <tr>
          <td width="50%">
          <div style="min-height: 150px;">
            <p style="font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 16px; line-height: 21px; font-weight: 700; text-align: center;">Your Challenge</p>
            <p style="margin:0 10px; font-family: Manrope, sans-serif; color: rgb(76, 76, 76); font-size: 14px; line-height: 22px; padding: 10px 0px; text-align: center;">Struggling with delayed completion caused by extended processing times.</p>
          </div>
          </td>
          <td width="50%">
          <div style="min-height: 150px;">
            <p style="font-family: 'Manrope', sans-serif; color: #4C4C4C; font-size: 16px; line-height: 21px; font-weight: 700; text-align: center;">Your Challenge</p>
            <p style="margin:0 10px; font-family: Manrope, sans-serif; color: rgb(76, 76, 76); font-size: 14px; line-height: 22px; padding: 10px 0px; text-align: center;">Unlock a 10x faster turnaround by embracing a paperless approach through TezSign's secure platform.</p>
          </div>
          </td>
        </tr>	  
        
        
        <tr>
          <td colspan="2" rowspan="1">
            <div><br>
            </div>
          </td>
        </tr>	
        <tr>
          <td colspan="2" rowspan="1">
            <div><br>
            </div>
          </td>
        </tr>		  
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your CTA Button Goes Here -->
          <a href="https://auth.tezsign.com/realms/tezsign-s1/protocol/openid-connect/registrations?client_id=tezsign-frontend&redirect_uri=https%3A%2F%2Fwww.tezsign.com%2Flogin&state=695dd5c5-5cf5-46fe-9cb8-6fbbb9e25260&response_mode=fragment&response_type=code&scope=openid&nonce=803c09f4-1bd4-4a81-b689-50667ecd9cca" target="_blank">
            <div style="width: 250px; font-family: 'Manrope', sans-serif; font-size: 16px; line-height: 21px; color: #FFF; text-align: center; margin: 30px; padding: 15px 35px; background: #F59700; border-radius: 50px; margin: 0 auto;">Redeem Now</div>
          </a>
          </td>
        </tr>  
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Content Goes Gere -->
          <div style="width:100%; font-family: 'Manrope', sans-serif; color: #DD0202; font-size: 12px; line-height: 22px; text-align: center; padding: 10px 0;">
             The redemption period is valid from January 1, 2024, to January 31, 2024.
          </div>
          </td>
        </tr>		  
        <tr>
          <td colspan="2" rowspan="1">
            <div><br>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">
          <!-- Your Footer Content Goes Gere -->        
          <p style="font-size: 12px; line-height: 18px; text-align: center;"><a href="https://www.tezsign.com/privacy-policy" target="_blank" style="color: #4C4C4C;">Privacy Policy</a> | <a href="https://www.tezsign.com/terms-of-use" target="_blank" style="color: #4C4C4C;">Terms of Use</a> | <a href="https://www.tezsign.com/#contact" target="_blank" style="color: #4C4C4C;">Contact Us</a></p>
          <p style="font-size: 12px; line-height: 18px; text-align: center;">Copyright © 2024 TezSign. All rights reserved.</p>	
          </td>
        </tr>
      </tbody>
    </table>
    </div>    
    `
  }
  // Add more templates as needed
];

export const maxLengthAllowed = {
  0: {
    // in app
    subject: 80,
    body: 350
  },
  1: {
    // email
    subject: 80,
    body: 10000
  },
  2: {
    // sms
    body: 170
  },
  // 3: {
  //   // web push
  //   subject: 80,
  //   body: 350
  // },
  // 4: {
  //   // mobile push
  //   subject: 80,
  //   body: 350
  // },
  5: {
    // top tips
    body: 135
  }
};

export default {};
