import { get, httpDelete, post, put } from './httpService';

const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

const getFeatureList = async () => {
  const response = await get(`${apiUrl}/admin/features`);
  return response;
};
const getFeatureDetail = async id => {
  const response = await get(`${apiUrl}/admin/features/${id}`);
  return response;
};
const createFeatures = async params => {
  try {
    const response = await post(`${apiUrl}/admin/features`, params);
    return response;
  } catch (error) {
    return false;
  }
};
const updateFeatures = async (params, id) => {
  try {
    const response = await put(`${apiUrl}/admin/features/${id}`, params);
    return response;
  } catch (error) {
    return false;
  }
};
const deleteFeatures = async id => {
  const response = await httpDelete(`${apiUrl}/admin/features/${id}`);
  return response;
};
const searchFeatureByName = async name => {
  const response = await get(`${apiUrl}/admin/features/search?name=${name}`);
  return response;
};

export {
  getFeatureList,
  getFeatureDetail,
  createFeatures,
  updateFeatures,
  deleteFeatures,
  searchFeatureByName
};
