import moment from 'moment';
import { getCountries } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';

export const convertDateStringToUtcString = (date, dateFormat, timezone) => {
  return moment(date, dateFormat, timezone).utc().toISOString();
};

export const convertMomentToUtcString = (dateMoment, timezone) => {
  return moment(dateMoment, timezone).utc().toISOString();
};

export const getFlagEmoji = countryCode => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const getCountryOptionList = (hasFlag = true) => {
  const countryOptions = [];
  getCountries()
    .sort((a, b) => {
      if (en[a] < en[b]) {
        return -1;
      }
      if (en[a] > en[b]) {
        return 1;
      }
      return 0;
    })
    .map(country =>
      countryOptions.push({
        value: country,
        label: !hasFlag
          ? en[country]
          : `${getFlagEmoji(country)}  ${en[country]}`
      })
    );
  return countryOptions;
};
